import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';



const useStyles  = makeStyles({
    CourseHeading:{
       marginBottom:'4px',
       padding:'0px 8px',
       fontSize:'14px',
       fontWeight:'bold',
       color:'#333',
       whiteSpace: 'nowrap',
       textOverflow: 'ellipsis',
       overflow: 'hidden'

    },
    Coursedesc:{
        padding:'0px 8px',
        fontSize:'12px',
        color:'#7f7f7f',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    }
  });

export default function CourseItem(props) {

    const classes = useStyles();
    let {item={}, postType = 'courses', hasCourses = false} = props;
    let {image = '' , title = '', desc = '', type = 'cards'} = item;

    const destination = hasCourses ? `/${postType}/${item.id}/courses` : `/${postType}/${item.id}`;

    return (
        <Grid item md={6} xs={6} className={`course`}>
            <Link to={destination}>
                <div className="inner">
                    <div className="image-course" style={{backgroundImage:`url('${image}')`}}></div>
                    <div className="course-details">
                        <Typography variant="h6" component="h3" className={classes.CourseHeading}>
                            {title}
                        </Typography>
                        <Typography component="p" className={classes.Coursedesc}>
                            {desc}
                        </Typography>
                    </div>
                </div>
            </Link>
        </Grid>
    )
}
