import React from 'react';
import { Search } from 'react-feather'
import axios from 'axios';
import { getToken } from '../../../services/auth';
import globalVars from '../../../consts/vars';
import { useState } from 'react';

function SearchBox({placeholder='جستجو کنید ...', setCoursesCard, setCoursesVideo, setLoading , setCourses, setSearchParam}) {

    const [delayTimer, setDelayTimer] = useState(null);

    const doSearch = (search) => {
        
        setSearchParam(search);
        setDelayTimer(clearTimeout(delayTimer));
        setLoading(true);
        setDelayTimer(setTimeout(function() {
            axios({
                method: 'get',				            				
                url: `${globalVars.API_URL}/courses?bought=true&s=${search}`,
                headers: {'Authorization' : `${getToken()}`}
            }).then( (res) => {    
                let {courses = []} = res.data;           
                setLoading(false);
                setCourses(courses);
                let videoCourse = [];
                let cardCourse = [];
                for ( let item of courses ) {
                    if ( item.type == 'card' ) {
                        cardCourse.push(item);
                    } else if ( item.type == 'video' ) {
                        videoCourse.push(item);
                    }
                }
                setCoursesCard(cardCourse);
                setCoursesVideo(videoCourse);
            });
        }, 1000))

    }

    return (
        <div className="search-holder">
            <form action="#">
                <input type="text" placeholder={placeholder} onKeyUp={(e) => {
                    doSearch(e.target.value);
                }} />
                <button type="submit">
                    <Search size={20} />
                </button>
            </form>
        </div>
    );
}

export default SearchBox;