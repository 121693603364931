import {logOut} from '../../../services/auth';

export default [
    {
        id: 1,
        menuitem:'ورود',
        link: `/login`
    },
    {
        id: 2,
        menuitem:'ثبت نام',
        link: `/register`
    },
    {
        id: 3,
        menuitem:'اخبار',
        link: `/blog`
    }
]