import {logOut} from '../../../services/auth';

export default [
    {
        id: 1,
        menuitem:'پروفایل کاربری',
        link: `/profile`
    },
    {
        id: 2,
        menuitem:'دوره های من',
        link: `/profile/my-courses`
    },
    {
        id: 3,
        menuitem:'سوالات متداول',
        link: `/faqs`
    },
    {
        id: 4,
        menuitem:'پشتیبانی',
        link: `/profile/tickets`
    },
    {
        id: 5,
        menuitem:'درباره ما',
        link: `/about-us`
    },
    {
        id: 6,
        menuitem:'تماس با ما',
        link: `/contact-us`
    }
]