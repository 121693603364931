import { Box, Button, Container, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import Page from '../global/page'
import LoadingPart from '../global/loadingPart';
import axios from 'axios';
import globalVars from '../../consts/vars';
import { ResHandler, faNumberToEnNumber } from '../../services/utils';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { logIn } from '../../services/auth';
import { useHistory } from 'react-router-dom';

export default function Login() {

    
    const navigate = useHistory();
    const { enqueueSnackbar } = useSnackbar();
	const [mobile , setMobile] = useState('');
    const [step, setStep] = useState(1);
    
    return (
        <Page className="register" heading="ورود">
        <Container>
            <div className="register-holder">
            {step == 1 ? <Formik       
                        initialValues={{
                            mobile: ''
                        }}         
                        validationSchema={
                            Yup.object().shape({
                                mobile: Yup.string()
                                    .required('شماره موبایل ضروری می باشد')
                                    .matches(
                                    /^(\+98|0098|98|0)?9\d{9}$/,
                                    "لطفا موبایل معتبر انتخاب نمایید."
                                )
                            })
                        }
                        onSubmit={(form , actions) => {   

														
							let mobile = form.mobile;
                                                
                            const formData = new FormData();                                
							formData.append('mobile', faNumberToEnNumber(mobile));                                                          

                            axios({
								method: 'post',								
                                url: `${globalVars.API_URL}/login`,
                                data: formData
                            }).then( (res) => {               
								ResHandler(res , (success) => {
									let {messages = {}} = success;
									for (const [key, value] of Object.entries(messages)) {
										enqueueSnackbar(value, {variant:'success'});
									}   
									setStep(2);
									actions.setSubmitting(false);    
								} , (error) => {
									let {errors = {}} = error;
									for (const [key, value] of Object.entries(errors)) {
										enqueueSnackbar(value, {variant:'error'});
									} 
									actions.setSubmitting(false);    
								});                                         								                                  
                            });

                            // setBodyLoading(true);                                             
                            
                        }}
                    >
                        {({
                        errors,
                        setFieldValue,
                        handleBlur,
                        handleChange,              
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values
                        }) => (
						<form onSubmit={handleSubmit} onChange={(e) => {
                            let val = e.target.value.replace(/ /g,'');
                            setFieldValue('mobile', faNumberToEnNumber(val) );
                            setMobile(faNumberToEnNumber(e.target.value))
                        }}>
							{!isSubmitting ? <>
								<TextField
									label="شماره موبایل "
									variant="outlined"
									color="primary"
                                    name="mobile"
                                    value={values.mobile}
									error={Boolean(touched.mobile && errors.mobile)}
									helperText={touched.mobile && errors.mobile || values.mobile.length != 11 && 'لطفا یک شماره موبایل ۱۱ رقمی وارد نمایید.'}
									onBlur={handleBlur} onChange={handleChange}
								/>
								<Button variant="contained" type="submit">
									دریافت کد تایید
								</Button>								
							</> : <LoadingPart /> }
						</form>                        
                        )}
                    </Formik> : ''}
					{step == 2 ? <Formik       
                        initialValues={{							
                            code: ''
                        }}         
                        validationSchema={
                            Yup.object().shape({
								code: Yup.string().required('وارد کردن کد ورود الزامی می باشد.')
                            })
                        }
                        onSubmit={(form , actions) => {   

														
							let code = form.code;
							
                            const formData = new FormData();                                							
							formData.append('code', code);    
							formData.append('mobile', mobile); 

                            axios({
								method: 'post',								
                                url: `${globalVars.API_URL}/verifyCode`,
                                data: formData
                            }).then( (res) => {               
								ResHandler(res , (success) => {
									let {messages = {}} = success;
                                    let {token = ''} = messages;
                                    
                                    if ( token )
                                        axios({
                                            method: 'get',								
                                            url: `${globalVars.API_URL}/profile`,
                                            headers: {'Authorization' : `Bearer ${token}`}
                                        }).then( (res) => { 
                                            let {user = {}} = res.data;                                            
                                            logIn(token, navigate , () => {
                                                actions.setSubmitting(false);    
                                            }, user);	                                        								                                  
                                        });    

                                    													
								} , (error) => {
									let {errors = {}} = error;
									for (const [key, value] of Object.entries(errors)) {
										enqueueSnackbar(value, {variant:'error'});
									} 
									actions.setSubmitting(false);    
								});                                         								                                  
                            });                                           
                            
                        }}
                    >
                        {({
                        errors,
                        setFieldValue,
                        handleBlur,
                        handleChange,              
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values
                        }) => (
						<form onSubmit={handleSubmit} onChange={(e) => {
                            let val = e.target.value.replace(/ /g,'');
							setFieldValue('code',faNumberToEnNumber(val));
						}}>
							{!isSubmitting ? <>
								<TextField
									label="کد تایید"
									variant="outlined"
									color="primary"
                                    name="code"
                                    value={values.code}
									error={Boolean(touched.code && errors.code)}
									helperText={touched.code && errors.code}
									onBlur={handleBlur} onChange={handleChange}
								/>
								<Button variant="contained" type="submit">
									ثبت کد تایید
								</Button>								
							</> : <LoadingPart /> }
						</form>                        
                        )}
                    </Formik> : ''}
            </div>
        </Container>
    </Page>
    )
}
