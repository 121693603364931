import { Typography, Button, makeStyles } from "@material-ui/core";
import { numberFormat } from "../../../services/utils";
import { ChevronLeft } from "react-feather";

const useStyles  = makeStyles({
    CourseName:{
        color:'#092f52',
        fontWeight:'bold',  
    },
    Detail:{
      color:'#7f7f7f',
      fontSize:'12px',
      marginRight:'4px'
    },
    Icon:{
      border:'none',
      background:'none',
      width:'10px',
      height:'10px',
      border:'1px solid #000',
      borderRadius:'5px',
      padding:'4px',
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      marginRight:'8px',

    },
    FirsPrice:{
        fontSize:'14px',
        color:'#7f7f7f',
        textDecoration:'line-through'
    },
    CurrentPrice:{
        fontSize:'20px',
        color:'#333',
        marginRight:'8px',
    },
    BuyBtn: {
        color: '#fff',
        backgroundColor: '#fd98aa',
        borderRadius: '30px',
        height: '40px',
        padding: '0 28px',
        fontSize: '20px',
        '&:hover': {
            backgroundColor: '#c73e56'
        }
    },
    CardsTitle: {
        maxWidth: '45%',
        fontSize: '12px',
        fontWeight: '300',
        color: '#7f7f7f'
    },
    CardsBtn: {
        color: '#fff',
        backgroundColor: '#38c883 !important',
        borderRadius: '30px',
        height: '40px',
        padding: '0 28px',
        fontSize: '18px',
        maxWidth: '185px',
        whiteSpace: 'nowrap'
    },
    startExam: {
        color: '#fff',
        backgroundColor: '#fd98aa !important',
        borderRadius: '30px',
        height: '40px',
        fontSize: '18px',
        maxWidth: '185px',
        padding: '0 28px',
        whiteSpace: 'nowrap'
    }
  });

const FooterToast = ({title='', buttonText='',type='', price='', offPrice='', cardCount=0, action, isFinal = false}) => {

    price = parseInt(price);
    const classes = useStyles();

    switch ( type ) {
        case 'exam':
            return <div className="toast toast-bottom footer-holder exam">
                {title ? <Typography component="span" className={classes.CardsTitle}>
                        {title}
                    </Typography> : ''}
                <Button className={classes.startExam} endIcon={<ChevronLeft />} onClick={()=>action()}>
                    شروع خودآزمایی
                </Button>
            </div>
        case 'examFinal':
            return <div className="toast toast-bottom footer-holder exam finalExam">
                <Typography component="span" className="spanFinalExam">
                    {title}
                </Typography>
                <Button disabled={isFinal ? false : true} className={classes.startExam} endIcon={<ChevronLeft />} onClick={()=>action()}>
                    {buttonText || 'اتمام خودآزمایی'}
                </Button>
            </div>
        case 'cards':
            return <div className="toast toast-bottom cards footer-holder">
                    {title ? <Typography component="span" className={classes.CardsTitle}>
                        {title}
                    </Typography> : ''}
                    <Button className={classes.CardsBtn} onClick={()=>action()} endIcon={<ChevronLeft />}>
                        مشاهده کارت ها
                    </Button>
                </div>
        case 'currentCards':
            return <div className="toast toast-bottom footer-holder" onClick={()=>action()}>
                <div className="review">
                    {title}
                <div className="card-amount">
                    {cardCount}
                </div>
                </div>
            </div>
        case 'buy':
            return <div className="toast toast-bottom footer-holder">
                        <div className="righ-footer">
                            {offPrice ? <div className="first-price">
                                <Typography component="span" className={classes.FirsPrice}>
                                    {numberFormat(offPrice)}
                                </Typography>
                            </div> : ''}
                            {price ? <div className="current-price">
                                <Typography component="span" className={classes.CurrentPrice}>
                                    {numberFormat(price)}
                                </Typography> 
                                <Typography component="span" className={classes.CurrentPrice}>
                                    تومان
                                </Typography> 
                            </div> : ''}
                        </div>
                        <div className="left-footer buy-btn">
                            <Button className={classes.BuyBtn} onClick={()=>action()}>
                                {title}
                            </Button>
                        </div>
                    </div>
        default:
            return '';
    }
    
}
export default FooterToast;