import React, { useState, useEffect } from 'react'
import Page from '../global/page';
import { Container , Typography , makeStyles } from '@material-ui/core';
import { Copy, Play } from 'react-feather';
import ProgressLine from '../global/progress-line';
import ProgressNumber from '../global/progress-number';
import data from './lessonData';
import reviewData from './reviewData';
import dataCourses from './../courses/data';
import LessonItem from '../LessonItem';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AddToCard from '../add-to-card';
import FooterToast from '../global/FooterToast';
import { getCourseData } from '../../services/utils';
import axios from 'axios';
import globalVars from '../../consts/vars';
import {getToken} from '../../services/auth';
import LoadingPart from '../../components/global/loadingPart';
import {getParameterByName} from '../../services/utils';

const useStyles  = makeStyles({
    CourseName:{
        color:'#092f52',
        fontWeight:'bold',  
    },
    Detail:{
      color:'#7f7f7f',
      fontSize:'12px',
      marginLeft:'4px'
    },
    Icon:{
      border:'none',
      background:'none',
      width:'10px',
      height:'10px',
      border:'1px solid #000',
      borderRadius:'5px',
      padding:'4px',
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      marginRight:'8px',

    }
  });

export default function CourseSingle(props) {

  const classes = useStyles();
  const {course_id} = useParams();
  const [reviews] = useState(reviewData);
  const [loading, setLoading] = useState(true);  
  const [lessons, setLessons] = useState(data || []);
  const [progress, setProgress] = useState(0);
  const [course , setCourse] = useState( {} );
  const [courseReviewCards , setCourseReviewCards] = useState( {} );
  const navigate = useHistory();

  const showCurrentCards = () => {
    navigate.push(`/courses/${course_id}/review-cards`)
  }

  const getCourse = () => {
      setLoading(true);      
      axios({
          method: 'get',				            				
          url: `${globalVars.API_URL}/courses/${course_id}`,
          headers: {'Authorization' : `${getToken()}`}
      }).then( (res) => {    
          let {lessons = [], course = {}, hasReviewCards = false, reviewCards = []} = res.data;                           
          setProgress(res.data.progress || 0);
          setLoading(false);
          setCourse(course);
          setLessons(lessons);
          if ( hasReviewCards )
            setCourseReviewCards(reviewCards);
      });             
  }    

  useEffect(() => {
      getCourse();
  } , [course_id])


    let {title = '' , image = '' , desc = '', id=0, totalCard, is_bought = false, type = ''} = course;
    let {reviewAmount} = reviews;
    const itemTypeFa = type == 'card' ? 'کارت آموزشی' : 'ویدیو';
    return (
      !loading ? <>            
        {
          is_bought ? <Page pageName="page lesson" auth_req={true}>
          <Container maxWidth="xs">
              <div className="heading-holder">
                <div className={classes.Icon}>
                  {type == 'card' ? <Copy size={20} /> : <Play size={20} />}
                </div>
                <Typography variant="h6" component="h2" className={classes.CourseName}>
                    {title}
                </Typography> 
                <ProgressNumber progress={parseInt(progress)}/>
              </div>
              <div className="course-detail mb-8">
                <Typography component="span" className={classes.Detail}>
                        {totalCard}
                </Typography>
                <Typography component="span" className={classes.Detail}>
                        {itemTypeFa}
                </Typography>
              </div>
          </Container>
          <ProgressLine progress={parseInt(progress)} />
          <Container>
           <div className="lesson-item-holder pb-60">{lessons && lessons.length ? <ul className="lesson-items">
            {lessons.map( (item , key) => <LessonItem key={key} item={item} index={key} course={course} hasStatus={true} />)}
              </ul> : ''} </div>
          </Container>
          {course.type == 'card' && courseReviewCards.length ? <FooterToast type='currentCards' title='مشاهده کلمات سخت' cardCount={courseReviewCards.length} action={showCurrentCards} /> : ''}
        </Page> : <AddToCard lessons={lessons} course={course} />
        }
      </> : <LoadingPart />
    )
}
