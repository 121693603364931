import { Button, Dialog, DialogTitle, DialogContent, Typography, DialogActions, DialogContentText, IconButton } from "@material-ui/core"
import globalVars from "../../../consts/vars";
import axios from 'axios';
import { getToken } from "../../../services/auth";
import LoadingPart from '../../global/loadingPart';
import { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import LessonState from "../lesson-state";
import { X } from "react-feather";

const ButtomRate = ({cardIndex = 0, cardId = '', setCardIndex, totalCard = 0}) => {


    const [loading, setLoading] = useState(false);
    const [loadingGetData, setLoadingGetData] = useState(true);
    const [openDialogFinal, setOpenDialogFinal] = useState(false);
    const [allRes, setAllRes] = useState({});
    const { enqueueSnackbar , closeSnackbar} = useSnackbar();
    const action = key => (
        <IconButton>
            <X color="#fff" size='16px' onClick={() => { closeSnackbar(key) }} />    
        </IconButton>  
    )
    const { course_id = null, lesson_id = null } = useParams(); 
    const navigate = useHistory();
    const handleFinalDialogClose = () => {
        setOpenDialogFinal(false);
    }

    const NextCard = (rateVal = '') => {
        let lastFlag = cardIndex == totalCard ? true : false;
        if ( cardId )
        {
            const formData = new FormData();     
            let rateValFinal = [rateVal]; 
            formData.append('level' , rateValFinal);     
            setLoading(true);                     							            
            axios({
                method: 'post',								
                url: `${globalVars.API_URL}/cards/${cardId}/answer`,
                data: formData,
                headers: {'Authorization' : `${getToken()}`}
            }).then( (res) => {            
                if ( cardIndex < totalCard ) 
                    setCardIndex(parseInt(cardIndex)+1);
                else 
                    setCardIndex(parseInt(totalCard));      
                if ( lastFlag )
                    {
                        getLesson();
                    }
                setLoading(false);                          								                                  
            });    
        }      
    } 

    const getLesson = () => {       
        setOpenDialogFinal(true); 
        setLoadingGetData(true);
        axios({
            method: 'get',				            				
            url: `${globalVars.API_URL}/courses/${course_id}/lessons/${lesson_id}`,
            headers: {'Authorization' : `${getToken()}`}
        }).then( (res) => {    
            setLoadingGetData(false);
            setAllRes(res.data);
        }, (err) => {
            setLoadingGetData(false);
            let {data = {}} = err.response;
            for (const [key, value] of Object.entries(data.errors)) {
                enqueueSnackbar(value, {variant:'error' , action});               
            }               
        });             
    }  

    return <div className="bottom-rate">        
        <Dialog
                open={openDialogFinal}
                keepMounted
                onClose={handleFinalDialogClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className="dialogCloseExam dialogExamFinal"
            >
            {!loadingGetData ? <>
                <DialogTitle id="alert-dialog-slide-title">اتمام کارت ها</DialogTitle>
                <DialogContent>                                
                    <DialogContentText id="alert-dialog-slide-description">                
                        <Typography component="p">وضعیت پاسخ گویی شما به کارت ها</Typography>
                    </DialogContentText>
                    {(allRes.easy || allRes.hard || allRes.medium || allRes.unAnswered ) ? <LessonState type='vertical' item={allRes} /> : 'بعد از خرید دوره، قادر به دیدن وضعیت پاسخگویی خود به کلمات خواهید بود.'}
                </DialogContent>
                <DialogActions>    
                    <Button className="continue" onClick={() => lesson_id ? navigate.push(`/courses/${course_id}/lesson/${lesson_id}`) : navigate.push(`/courses/${course_id}`)} color="primary">
                        بازگشت به درس
                    </Button>
                    <Button className="continue" onClick={() => handleFinalDialogClose()} color="secondary" style={{backgroundColor: '#fd98aa'}}>
                    مرور مجدد 
                    </Button>
                </DialogActions>
            </> : <LoadingPart />}
        </Dialog>
        <div className={`loading ${loading ? 'active' : ''}`}>
            <LoadingPart />
        </div>
        <Button className="hard" onClick={() => NextCard('hard')}>
            سخت
        </Button>
        <Button className="medium" onClick={() => NextCard('medium')}>
            متوسط
        </Button>
        <Button className="easy" onClick={() => NextCard('easy')}>
            آسون
        </Button>
    </div>
}   
export default ButtomRate;