import moment from "jalali-moment";

export const ResHandler = (res , success , error) => {
  
    let {data} = res;
    let {code, errors = {}} = data;
    if ( parseInt(code) > 400 ) {
        error(data);
             
    } else {
        success(data);
    }
}

export const Percentage = (num1 , num2) => {
    var outOff = num2;
    var value = num1;
    var result = (value * 100) / outOff;
    return result;
}

export const millisecondsToHHMMSS = (duration) => {
    const sec = Math.round(duration);
    const hours = Math.floor(sec / 3600); // get hours
    const minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
    const seconds = sec - hours * 3600 - minutes * 60; //  get seconds
    // add 0 if value < 10
    let sHours = hours > 0 ? `${hours}:` : '';
    let sMinutes = `${minutes}`;
    let sSeconds = `${seconds}`;
  
    if (minutes < 10) {
      sMinutes = '0' + sMinutes;
    }
    if (seconds < 10) {
      sSeconds = '0' + sSeconds;
    }
    return sHours + sMinutes + ':' + sSeconds;
    // Return is HH:MM:SS
  };

export const numberFormat = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getCourseData = (id , data) =>  {
  for ( let item of data ) {
    if ( item.id == id ) {
      return item;
    }
  }
  return;
}


export const getLessonData = (id , data) => {
  for ( let item of data ) {
    if ( item.id == id ) {
      return item;
    }
  }
  return;
}

export const plusZero = (index) => {
  return index < 10 && index != 0 ? `0${index}` : index;
}

export const scrollTo = (id) => {
  try {
    var elmnt = document.getElementById(id);
    elmnt.scrollIntoView({behavior: "smooth"});
  } catch(e) {
    
  }
}

export const getParameterByName = (name, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const faNumberToEnNumber = (str) => {

  let persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g];
  let arabicNumbers  = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];

  if (typeof str === 'string')
  {
      for(var i=0; i<10; i++)
          {
              str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
          }
  }

  return str;
}

export const convertDate = (createDateTime, dateformat = 'D MMMM jYYYY') => {
  try {
      let pCreatedItem = '';
      let arrayDate = [];
      let timeArrayDate = [];
      if ( createDateTime ) {
          arrayDate = createDateTime.split('T')[0].split('-');
          timeArrayDate = createDateTime.split('T')[1].split(':');
          pCreatedItem = moment(`${arrayDate[0]}/${arrayDate[1]}/${arrayDate[2]}/${timeArrayDate[0]}/${timeArrayDate[1]}/${timeArrayDate[2]}/`, 'YYYY/MM/D/hh/mm/ss');
          return pCreatedItem.locale('fa').format(dateformat);
      } else {
          return;
      }
  } catch(e) {
      console.log('error' , e);
      return '';
  }
}

export const convertFA = word => {
  switch(word) {
    case 'low':
      return 'پایین';
    case 'medium':
      return 'متوسط';
    case 'high':
      return 'بالا';
    case 'pending':
      return 'در انتظار بررسی';
    default:
      return word;
  }
}