import React from 'react';
import Page from '../../global/page';
import { Container, TextField, Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Formik } from "formik";
import * as Yup from 'yup';
import axios from 'axios';
import globalVars from '../../../consts/vars';
import { getToken } from '../../../services/auth';
import { useSnackbar } from 'notistack';
import LoadingPart from '../../global/loadingPart';
import { useHistory } from 'react-router-dom';

function TicketAdd(props) {
    
    const navigate = useHistory();
    const {enqueueSnackbar} = useSnackbar();

    return (
        <Page className="tickets" heading="ثبت تیکت">
            <Container>
                <Formik       
                    initialValues={{
                        subject: '',
                        message: '',
                        priority: 'low',
                    }}         
                    validationSchema={
                        Yup.object().shape({
                            message: Yup.string().required('توضیحات ضروری می باشد'),
                            subject: Yup.string().required('عنوان ضروری می باشد')                           
                        })
                    }
                    onSubmit={(form , actions) => {   

                                                    
                        // let mobile = form.mobile;
                        let subject = form.subject;
                        let message = form.message;
                        let priority = form.priority;
                                            
                        const formData = new FormData();                                
                        // formData.append('mobile', mobile);                                                          
                        formData.append('subject', subject); 
                        formData.append('message', message); 
                        formData.append('priority', priority); 

                        axios({
                            method: 'post',								
                            url: `${globalVars.API_URL}/tickets`,
                            data: formData,
                            headers: {'Authorization' : `${getToken()}`}
                        }).then( (res) => {        
                            enqueueSnackbar(res.data.message, {variant:'success'});
                            navigate.push('/profile/tickets');
                        }, (err) => {
                            let {message = ''} = err.response.data; 
                            enqueueSnackbar(message, {variant:'error'});
                            actions.setSubmitting(false); 
                        });
                                        
                        
                    }}
                >
                    {({
                    errors,
                    handleBlur,
                    handleChange,              
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                    }) => (
                    <form onSubmit={handleSubmit} style={{marginTop:"24px"}}>
                        {!isSubmitting ? <>        
                            <TextField
                                label="موضوع تیکت"
                                variant="outlined"
                                color="primary"
                                name="subject"
                                defaultValue={values.subject}
                                error={Boolean(touched.subject && errors.subject)}
                                helperText={touched.subject && errors.subject}
                                onBlur={handleBlur} onChange={handleChange}
                            />   
                            <TextField
                                label="پیام"
                                multiline={true}
                                rows={6}
                                variant="outlined"
                                color="primary"
                                name="message"
                                defaultValue={values.message}
                                error={Boolean(touched.message && errors.message)}
                                helperText={touched.message && errors.message}
                                onBlur={handleBlur} onChange={handleChange}
                            />  
                            <FormControl>
                                <InputLabel id="priority-select-label">الویت</InputLabel>
                                <Select
                                labelId="priority-select-label"
                                id="priority-select"
                                name="priority"
                                value={values.priority}
                                onChange={handleChange}
                                >
                                <MenuItem value={'low'}>پایین</MenuItem>
                                <MenuItem value={'medium'}>متوسط</MenuItem>
                                <MenuItem value={'high'}>زیاد</MenuItem>
                                </Select>
                            </FormControl>                        
                            <Button variant="contained" color="primary" type="submit">
                                افزودن تیکت
                            </Button>								
                        </> : <LoadingPart /> }
                    </form>                        
                    )}
                </Formik>
            </Container>
        </Page>
    );
}

export default TicketAdd;