import { useState } from 'react';
import { Typography , makeStyles } from '@material-ui/core';
import { Check, Copy, Lock, Play } from 'react-feather';
import { Link, useParams } from 'react-router-dom';
import { plusZero } from '../../services/utils';

const useStyles  = makeStyles({
    Index:{
        fontSize:'30px',
        color:'#e1e5f3',
        marginRight:'16px'
    },
    Title:{
        fontSize:'14px',
        fontWeight:'bold',
        color:'#333',

    },
    Count:{
        fontSize:'12px',
        fontWeight:'300',
        color:'#7f7f7f',
    }
})
    
export default function LessonItem(props) {

    const classes = useStyles();
    const {course_id} = useParams();
    let {item={} , course = {}, index = 0 , hasStatus = false , className} = props;
    let { title = '', episode = 1, is_lock = true, passed = false , playing = false , short_desc = ''} = item;
    let {type} = course;
    let countAfter = 'کارت آموزشی';
    if ( type == 'video' )
        countAfter = 'دقیقه آموزشی';
 
    return (
        <Link to={`/courses/${course_id}/lesson/${item.id}`}>
            <li className={`lesson-item ${className}`}>
               <div className="lesson-session-index">
                    <Typography className={classes.Index}>
                        {plusZero(parseInt(episode))}
                    </Typography>
               </div>
               <div className={`lesson-session-detail ${!hasStatus ? 'mb-8' : ''}`}>
                    <div className="lesson-session-title">
                        <Typography component="h3" className={classes.Title}>
                            {title}
                        </Typography>
                    </div>
                    {short_desc ? <div className="lesson-session-card-count">
                        <Typography component="span" className={classes.Count}>
                            {`${short_desc}`}
                        </Typography>
                    </div> : ''}
               </div>
               {hasStatus ? <div className={`status ${is_lock ? 'locked' : ''} ${passed ? 'passed-active' : ''} ${playing ? 'playing' : ''}`}>
                   {is_lock ? <Lock size={22} /> : passed ? <Check size={22} /> : course.type == 'video' ? <Play size={20} /> : <Copy size={20} /> }
               </div> : ''}
            </li>
            </Link>
    )
}
