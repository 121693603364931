const initialState = {};
const settings = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_SETTINGS':
            return {
                ...action.editorsettings
            }   
        default:
            return state;
    }
}
export default settings;