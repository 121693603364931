import { makeStyles , Container, Box, Typography } from '@material-ui/core'
import PropTypes from 'prop-types';
import React, { useEffect } from 'react'
import CourseItem from './course-item';
import data from './MyCoursesData';
import dataCards from './MyCardsData';
import { useState } from "react"
import Page from '../../global/page';
import Tabs from '../tabs';
import dataCourses from './../../courses/data';
import { getCourseData } from '../../../services/utils';
import axios from 'axios';
import globalVars from '../../../consts/vars';
import { getToken } from '../../../services/auth';
import LoadingPart from '../../global/loadingPart';
import SearchBox from '../../global/searchBox';

const useStyles  = makeStyles({
    tabPage: {
        marginBottom: '32px',
        display: 'flex'
    }
});
export default function MyCourses() {

    const classes = useStyles();
    const [courses , setCourses] = useState([]);
    const [coursesCard , setCoursesCard] = useState([]);
    const [coursesVideo , setCoursesVideo] = useState([]);
    const [cards] = useState(dataCards || []);
    const [loading, setLoading] = useState([]);
    const [activeTab, setActiveTab] = useState('myCourses');
    const [searchParam, setSearchParam] = useState('');         

    const getCourses = () => {
        
        setLoading(true);
        
        axios({
            method: 'get',				            				
            url: `${globalVars.API_URL}/courses?bought=true&type=card`,
            headers: {'Authorization' : `${getToken()}`}
        }).then( (res) => {    
            let {courses = []} = res.data;           
            setLoading(false);
            setCourses(courses);
            let videoCourse = [];
            let cardCourse = [];
            for ( let item of courses ) {
                if ( item.type == 'card' ) {
                    cardCourse.push(item);
                } else if ( item.type == 'video' ) {
                    videoCourse.push(item);
                }
            }
            setCoursesCard(cardCourse);
            setCoursesVideo(videoCourse);
        });             
    }

    useEffect(() => {
        getCourses();
    } , [])

    let notFoundVideoCourse = !searchParam ? 'شما تاکنون دوره ویدیویی خریداری نکرده اید.' : 'دوره ویدیویی با این عنوان یافت نشد.';
    let notFoundCardCourse = !searchParam ? 'شما تاکنون دوره کارتی خریداری نکرده اید.' : 'دوره کارتی با این عنوان یافت نشد.';

    return (
        <Page className={`page-card ${classes.root}`} auth_req={true}>
           <div className="myCoursesTabs">
               <Tabs setActiveTab={setActiveTab} activeTab={activeTab} />
           </div>
           <Container>            
                <SearchBox setSearchParam={setSearchParam} setCoursesCard={setCoursesCard} setCoursesVideo={setCoursesVideo} setLoading={setLoading} setCourses={setCourses} />        
                {!loading ? <>
                    {activeTab == 'myCourses' ? <div className="card-holder">
                        {coursesVideo.length ? <ul className="cards">
                            {coursesVideo.map( (item , key) => {
                                return <CourseItem item={item} key={key} itemColor={{main: '#f2fcf7' , border: '#38c883'}} />
                            })}
                        </ul> : <Box display="flex" alignItems="center" justifyContent="center">                                    
                                <Typography component="p">{notFoundVideoCourse}</Typography>
                            </Box>}
                    </div> : ''}
                    {activeTab == 'myCards' ? <div className="card-holder">
                        {coursesCard.length ? <ul className="cards">
                            {coursesCard.map( (item , key) => {
                                return <CourseItem item={item} key={key} itemColor={{main: '#f2fcf7' , border: '#38c883'}} />
                            })}
                        </ul> : <Box display="flex" alignItems="center" justifyContent="center">                                    
                                <Typography component="p">{notFoundCardCourse}</Typography>
                            </Box>}
                    </div> : ''}
                </> : <LoadingPart /> }
           </Container>
        </Page>
    )
}
