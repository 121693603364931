export const setProfile = (profileObject = {}) => {
    localStorage.setItem('profileObject' , JSON.stringify(profileObject));    
}
export const getProfile = () => {
    let profileObject = JSON.parse(localStorage.getItem('profileObject'));
    return profileObject;    
}
export const logIn = (token, navigate, done, profile = null) => {    
    localStorage.setItem('accessToken' , token);     
    if ( profile )
        localStorage.setItem('profileObject' , JSON.stringify(profile));
    setTimeout(() => {
        window.location.href = '/';
        done();
    } , 300)       
}
export const logOut = () => {    
    localStorage.removeItem('profileObject');
    localStorage.removeItem('accessToken');  
    window.location.href = '/';                  
}
export const getToken = () => {
    return localStorage.getItem('accessToken') ? `Bearer ${localStorage.getItem('accessToken')}` : null;    
}
