import React from 'react'
import { Typography , makeStyles } from '@material-ui/core'
import { Copy, Play } from 'react-feather'
import { Link, useHistory } from 'react-router-dom'
import { CircularProgressbar } from 'react-circular-progressbar';

const useStyles  = makeStyles({
    MyCardName:{
        fontSize:'14px',
        fontWeight:'bold',
        color:'#333',
    },
    MyCardDetail:{
        fontSize:'12px',
        fontWeight:'300',
        color:'#7f7f7f',
        marginLeft:'4px',   
    },
})

export default function CourseItem(props) {

	let {item = {}, type = 'card' , itemColor = {}} = props;
	let {title = '', totalCard ='', progress = 0} = item;
	const navigate = useHistory();
    const classes = useStyles();
	let itemPercentage = item.progress;
	const itemTypeFa = type == 'card' ? 'کارت آموزشی' : 'ویدیو';
	return (
		<li className={`card type-${type}`} onClick={() => navigate.push(`/courses/${item.id}`)} style={{
			backgroundColor: itemColor.main,
			borderRight: `3px solid ${itemColor.border}`
		}}>
			<div className="right-card">
				<div className="card-heading">
					<div className="icon">
						<Play size={10} />
					</div>
					<Typography component="h2" className={classes.MyCardName}>
						{title}
					</Typography>
				</div>
				<div className="detail">
					<Typography component="span" className={classes.MyCardDetail}>
						{totalCard}
					</Typography>
					<Typography component="span" className={classes.MyCardDetail}>
						{itemTypeFa}
					</Typography>
				</div>
			</div>
			<div className="left-card">
				<CircularProgressbar 
					value={progress} 
					text={`${progress}%`} 
					styles={{
						root: {
							width: '40px',
							fill: '#fff',
							backgroundColor: '#fff',
							borderRadius: '50%'
						},
						path: {
						stroke: itemColor.border,
						strokeWidth: '5px',
						strokeLinecap: 'butt',
						transition: 'stroke-dashoffset 0.5s ease 0s',
						transform: 'rotate(0.25turn)',
						transformOrigin: 'center center',
						},
						trail: {
						stroke: '#fff',
						strokeLinecap: 'butt',
						transform: 'rotate(0.25turn)',
						transformOrigin: 'center center',
						},
						text: {
						fill: itemColor.border,
							fontSize: '24px',
						},
						background: {
							fill: '#3e98c7',
						},
					}}
				/>
			</div>
		</li>
	)
}
