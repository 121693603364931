export default [

    {
        id: 1,
        title: '1-50 کلمات پرکاربرد',
        cardCount: '50 کارت آموزشی',
        description: 'محتویات جلسه اول تست',
        passed: true,
        locked: false,
        playing:false,
        videoUrl: 'https://aspb25.cdn.asset.aparat.com/aparat-video/5be6eeac648f9d73217679bbab644b8d28392843-144p.mp4?wmsAuthSign=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjA1NmI0NjYxOGE5NjhlMTZjMjY5NDZlMjY2NjI5N2EzIiwiZXhwIjoxNjEwMzA4NzQxLCJpc3MiOiJTYWJhIElkZWEgR1NJRyJ9.HZ0Vu9PEE_y_mfkLijTaQ1lSacir11TwewIw63tFp84'
    },
    {
        id: 2,
        title: '50-100 کلمات پرکاربرد',
        description: 'محتویات جلسه دوم تست',
        cardCount: '50 کارت آموزشی',
        passed: true,
        locked: false,
        playing:false,
        videoUrl: 'https://hajifirouz1.cdn.asset.aparat.com/aparat-video/67cee6a62c2f9a1890b1beb3b5be092928665274-144p.mp4?wmsAuthSign=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjkzZWM5MzlhYTY2ZGMxZTE5NjdlOTkwOTM0YzMxOTY3IiwiZXhwIjoxNjEwMzA4NzYyLCJpc3MiOiJTYWJhIElkZWEgR1NJRyJ9.lCDuqbsDedEG7gA1uVmR-WHGWVlzvfZvZfqxUtGXXcY',
        exam: {
            title: 'خودآزمایی',
            subTitle: 'معرفی دوره، نکات مهم و کلیدی',
            questions: [
                {
                    id:1,
                    title: 'عنوان سوال شما',
                    items: [
                        'لورم ۱',
                        'لورم ۲',
                        'لورم ۳',
                        'لورم ۴'
                    ],
                    correct: 2
                },
                {
                    id:2,
                    title: 'عنوان سوال جدید شما',
                    items: [
                        'لورم ۱',
                        'لورم ۲',
                        'لورم ۳',
                        'لورم ۴'
                    ],
                    correct: 3
                },
                {
                    id:3,
                    title: 'عنوان سوال خیلی عجیب شما',
                    items: [
                        'لورم ۱',
                        'لورم ۲',
                        'لورم ۳',
                        'لورم ۴'
                    ],
                    correct: 1
                },

                {
                    id:4,
                    title: 'عنوان سوال شما',
                    items: [
                        'لورم ۱',
                        'لورم ۲',
                        'لورم ۳',
                        'لورم ۴'
                    ],
                    correct: 2
                },
                {
                    id:5,
                    title: 'عنوان سوال جدید شما',
                    items: [
                        'لورم ۱',
                        'لورم ۲',
                        'لورم ۳',
                        'لورم ۴'
                    ],
                    correct: 3
                },
                {
                    id:6,
                    title: 'عنوان سوال خیلی عجیب شما',
                    items: [
                        'لورم ۱',
                        'لورم ۲',
                        'لورم ۳',
                        'لورم ۴'
                    ],
                    correct: 1
                },
            ]

        }
    },
    {
        id: 3,
        title: '100-150 کلمات پرکاربرد',
        cardCount: '50 کارت آموزشی',
        passed: true,
        locked: false,
        playing:false,
        soundUrl: 'http://dl.nex1music.ir/1399/10/20/Hamid%20Hiraad%20-%20Khoda%20Nakonad%20[128].mp3?time=1610290231&filename=/1399/10/20/Hamid%20Hiraad%20-%20Khoda%20Nakonad%20[128].mp3',
        cards: [
            {
                index: 1,
                word: 'Affect (v)',
                intonation: '/əˈfekt/',
                shapes: {
                    noun: '<p>Effect </br> S1 W1 </p>',
                    verb: '<p>Affect </br> تاثیرگذاشتن بر چیزی / </br> تظاهرکردن به چیزی </br> S2 W1 </br> Effect </br> ایجاد کردن</p>',
                    adverb: '<p>Effectively</p>',
                    adjective: '<p>Affective </br> عاطفی </br> Effective </br> موثر، خیره کننده </br> S2 W1 </p>'
                },
                info: {
                    desc: '<p>There are diseases that affect the nervous system.</br> It is annoying that she tries to affect a British accent.</br> People lack confidence in their ability to effect change in society.</br> I could feel the effects of the drug.</br> Affective experiences are very important for the teachers.</br>Highly effective use of colors can help to highlight in a painting. Your Example:</p>',
                    note: '<p>• You say that something affects another thing:</br> Smoking affects your health.</br> Don’t Say: Smoking affects on your health.</br> have an effect </br> You say that something has an effect on another thing: Smoking has a bad effect on your health.</p>'
                },
                sounds: {
                    british: 'https://proxy.notificationsounds.com/sound-effects/hollow-582/download/file-sounds-1129-hollow.mp3',
                    american: 'https://proxy.notificationsounds.com/sound-effects/insight-578/download/file-sounds-1125-insight.mp3'
                }
            },
            {
                index: 2,
                word: 'Hello (n)',
                intonation: '/əˈfekt/',
                shapes: {
                    noun: '<p>Effect </br> S1 W1 </p>',
                    verb: '<p>Affect </br> تاثیرگذاشتن بر چیزی / </br> تظاهرکردن به چیزی </br> S2 W1 </br> Effect </br> ایجاد کردن</p>',
                    adverb: '<p>Effectively</p>',
                    adjective: '<p>Affective </br> عاطفی </br> Effective </br> موثر، خیره کننده </br> S2 W1 </p>'
                },
                info: {
                    desc: '<p>There are diseases that affect the nervous system.</br> It is annoying that she tries to affect a British accent.</br> People lack confidence in their ability to effect change in society.</br> I could feel the effects of the drug.</br> Affective experiences are very important for the teachers.</br>Highly effective use of colors can help to highlight in a painting. Your Example:</p>',
                    note: '<p>• You say that something affects another thing:</br> Smoking affects your health.</br> Don’t Say: Smoking affects on your health.</br> have an effect </br> You say that something has an effect on another thing: Smoking has a bad effect on your health.</p>'
                },
                sounds: {
                    british: 'https://proxy.notificationsounds.com/sound-effects/hollow-582/download/file-sounds-1129-hollow.mp3',
                    american: 'https://proxy.notificationsounds.com/sound-effects/insight-578/download/file-sounds-1125-insight.mp3'
                }
            },
            {
                index: 3,
                word: 'Say (n)',
                intonation: '/səˈy/',
                shapes: {
                    noun: '<p>SSW </br> S1 W1 </p>',
                    verb: '<p>Affect </br> تاثیرگذاشتن بر چیزی / </br> تظاهرکردن به چیزی </br> S2 W1 </br> Effect </br> ایجاد کردن</p>',
                    adverb: '<p>Effectively</p>',
                    adjective: '<p>Affective </br> عاطفی </br> Effective </br> موثر، خیره کننده </br> S2 W1 </p>'
                },
                info: {
                    desc: '<p>There are diseases that affect the nervous system.</br> It is annoying that she tries to affect a British accent.</br> People lack confidence in their ability to effect change in society.</br> I could feel the effects of the drug.</br> Affective experiences are very important for the teachers.</br>Highly effective use of colors can help to highlight in a painting. Your Example:</p>',
                    note: '<p>• You say that something affects another thing:</br> Smoking affects your health.</br> Don’t Say: Smoking affects on your health.</br> have an effect </br> You say that something has an effect on another thing: Smoking has a bad effect on your health.</p>'
                },
                sounds: {
                    british: 'https://proxy.notificationsounds.com/sound-effects/hollow-582/download/file-sounds-1129-hollow.mp3',
                    american: 'https://proxy.notificationsounds.com/sound-effects/insight-578/download/file-sounds-1125-insight.mp3'
                }
            },
            {
                index: 4,
                word: 'Sky (n)',
                intonation: '/skˈay/',
                shapes: {
                    noun: '<p>Effect </br> S1 W1 </p>',
                    verb: '<p>Affect </br> تاثیرگذاشتن بر چیزی / </br> تظاهرکردن به چیزی </br> S2 W1 </br> Effect </br> ایجاد کردن</p>',
                    adverb: '<p>Effectively</p>',
                    adjective: '<p>Affective </br> عاطفی </br> Effective </br> موثر، خیره کننده </br> S2 W1 </p>'
                },
                info: {
                    desc: '<p>There are diseases that affect the nervous system.</br> It is annoying that she tries to affect a British accent.</br> People lack confidence in their ability to effect change in society.</br> I could feel the effects of the drug.</br> Affective experiences are very important for the teachers.</br>Highly effective use of colors can help to highlight in a painting. Your Example:</p>',
                    note: '<p>• You say that something affects another thing:</br> Smoking affects your health.</br> Don’t Say: Smoking affects on your health.</br> have an effect </br> You say that something has an effect on another thing: Smoking has a bad effect on your health.</p>'
                },
                sounds: {
                    british: 'https://proxy.notificationsounds.com/sound-effects/hollow-582/download/file-sounds-1129-hollow.mp3',
                    american: 'https://proxy.notificationsounds.com/sound-effects/insight-578/download/file-sounds-1125-insight.mp3'
                }
            }
        ]
    },
    {
        id: 4,
        title: '150-200 کلمات پرکاربرد',
        cardCount: '50 کارت آموزشی',
        passed: false,
        locked: false,
        playing:false,
        soundUrl: 'http://dl.nex1music.ir/1399/09/28/Reza%20Bahram%20-%20Gole%20Maryam%20[128].mp3?time=1610291039&filename=/1399/09/28/Reza%20Bahram%20-%20Gole%20Maryam%20[128].mp3',
        cards: [
            {
                index: 1,
                word: 'Brilliance (v)',
                intonation: '/əˈfekt/',
                shapes: {
                    noun: '<p>Effect </br> S1 W1 </p>',
                    verb: '<p>Affect </br> تاثیرگذاشتن بر چیزی / </br> تظاهرکردن به چیزی </br> S2 W1 </br> Effect </br> ایجاد کردن</p>',
                    adverb: '<p>Effectively</p>',
                    adjective: '<p>Affective </br> عاطفی </br> Effective </br> موثر، خیره کننده </br> S2 W1 </p>'
                },
                info: {
                    desc: '<p>There are diseases that affect the nervous system.</br> It is annoying that she tries to affect a British accent.</br> People lack confidence in their ability to effect change in society.</br> I could feel the effects of the drug.</br> Affective experiences are very important for the teachers.</br>Highly effective use of colors can help to highlight in a painting. Your Example:</p>',
                    note: '<p>• You say that something affects another thing:</br> Smoking affects your health.</br> Don’t Say: Smoking affects on your health.</br> have an effect </br> You say that something has an effect on another thing: Smoking has a bad effect on your health.</p>'
                },
                sounds: {
                    british: 'https://proxy.notificationsounds.com/message-tones/credulous-512/download/file-sounds-889-credulous.mp3',
                    american: 'https://proxy.notificationsounds.com/notification-sounds/long-expected-548/download/file-sounds-1097-long-expected.mp3'
                }
            }
        ]
    },
    {
        id: 5,
        title: '200-250 کلمات پرکاربرد',
        cardCount: '50 کارت آموزشی',
        passed: false,
        locked: false,
        playing:true,
        soundUrl: ''
    },
    {
        id: 6,
        title: '250-300 کلمات پرکاربرد',
        cardCount: '50 کارت آموزشی',
        passed: false,
        locked: true,
        playing:false,
    }
]