import Page from "../global/page";
import { Formik } from "formik";
import * as Yup from 'yup';
import axios from 'axios';
import globalVars from "../../consts/vars";
import { useSnackbar } from "notistack";
import { TextField, Button, Box } from "@material-ui/core";
import LoadingPart from "../global/loadingPart";
import { getToken, getProfile, setProfile } from "../../services/auth";

const Profile = () => {
    
    const {enqueueSnackbar} = useSnackbar();
    const profile = getProfile() || {};

    return (
        <Page pageName="profile" heading="پروفایل من" auth_req={true}>
            <Box margin="24px">
                <Formik       
                        initialValues={{
                            name: profile.name || '',
                            email: profile.email || '',
                            mobile: profile.mobile || ''
                        }}         
                        validationSchema={
                            Yup.object().shape({
                                mobile: Yup.string()
                                    .required('شماره موبایل ضروری می باشد')
                                    .matches(
                                    /^(\+98|0098|98|0)?9\d{9}$/,
                                    "لطفا موبایل معتبر انتخاب نمایید."
                                ),
                                email: Yup.string().email('لطفا یک ایمیل معتبر وارد نمایید.').required('ایمیل ضروری می باشد'),
                                name: Yup.string().required('نام ضروری می باشد')                              
                            })
                        }
                        onSubmit={(form , actions) => {   

														
                            // let mobile = form.mobile;
                            let name = form.name;
                            let email = form.email;
                                                
                            const formData = new FormData();                                
                            // formData.append('mobile', mobile);                                                          
                            formData.append('name', name); 
                            formData.append('email', email); 
                                                        
                            axios({
								method: 'post',								
                                url: `${globalVars.API_URL}/profile`,
                                data: formData,
                                headers: {'Authorization' : `${getToken()}`}
                            }).then( (res) => {        

                                let {errors = null} = res.data;
                                
                                if ( !errors ) {
                                    let successMessage = res.data.message;
                                    axios({
                                        method: 'get',								
                                        url: `${globalVars.API_URL}/profile`,
                                        headers: {'Authorization' : `${getToken()}`}
                                    }).then( (res) => { 
                                        let {user = null} = res.data;  
                                        if ( user ) {
                                            setProfile(user);
                                            enqueueSnackbar(successMessage, {variant:'success'});	
                                        }   
                                        actions.setSubmitting(false);	                                  
                                    }); 
                                } else {
                                    for (const [key, value] of Object.entries(errors)) {
										enqueueSnackbar(value, {variant:'error'});
                                    } 
                                    actions.setSubmitting(false);	        
                                }
                                

                            }, (err) => {
                                let {message = ''} = err.response.data; 
                                enqueueSnackbar(message, {variant:'error'});
                                actions.setSubmitting(false); 
                            });
                                           
                            
                        }}
                    >
                        {({
                        errors,
                        handleBlur,
                        handleChange,              
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values
                        }) => (
						<form onSubmit={handleSubmit}>
							{!isSubmitting ? <>                            
								<TextField
									label="نام و نام خانوادگی "
									variant="outlined"
									color="primary"
                                    name="name"
                                    defaultValue={values.name}
									error={Boolean(touched.name && errors.name)}
									helperText={touched.name && errors.name}
									onBlur={handleBlur} onChange={handleChange}
								/>
                                <TextField
									label="شماره موبایل "
									variant="outlined"
									color="primary"
                                    name="mobile"
                                    disabled={true}
                                    defaultValue={values.mobile}
									error={Boolean(touched.mobile && errors.mobile)}
									helperText={touched.mobile && errors.mobile || values.mobile.length != 11 && 'لطفا یک شماره موبایل ۱۱ رقمی وارد نمایید.'}
									onBlur={handleBlur} onChange={handleChange}
								/>
                                <TextField
									label="ایمیل"
									variant="outlined"
									color="primary"
                                    name="email"
                                    defaultValue={values.email}
									error={Boolean(touched.email && errors.email)}
									helperText={touched.email && errors.email}
									onBlur={handleBlur} onChange={handleChange}
								/>                                
								<Button variant="contained" color="primary" type="submit">
									ذخیره
								</Button>								
							</> : <LoadingPart /> }
						</form>                        
                        )}
                    </Formik>
            </Box>
        </Page>
    )
}
export default Profile;