import { useState, useEffect } from "react";
import CategoryItem from "./categoryItem";
import data from './data';
import { Typography, Box, Container  ,makeStyles} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import axios from 'axios';
import { getToken } from "../../services/auth";
import globalVars from "../../consts/vars";
import TagItem from "./categoryItem";
import { useParams } from "react-router-dom";


const useStyles  = makeStyles({
    CategoryHeading:{
        color:'#092f52',
        fontWeight:'bold',  
    }
  });
export default function Tags() {

    const classess = useStyles();
    const [loading, setLoading] = useState(false);
    const {tax_name = null} = useParams();
    const [tags, setTags] = useState([]);
    
    const getTags = () => {
        setLoading(true);              
        axios({
            method: 'get',				            				
            url: `${globalVars.API_URL}/tags`,
            headers: {'Authorization' : `${getToken()}`}
        }).then( (res) => {    
            let { tags = [] } = res.data; 
            setTags(tags);                 
        });             
    }  
      
    useEffect(() => {
        getTags();
    } , [])    

    return (
        tags.length ? <>
            <Container>
                <Typography variant="h6" component="h2" className={classess.CategoryHeading}>
                    {tax_name ? tax_name.replace(/-/g, " ") : 'از کجا میخوای شروع کنی ؟ '}
                </Typography>
            </Container>
            <div className="Tags-holder">
                {tags.length ? <ul className="Tags">
                    {tags.map( (item , key) => <TagItem item={item} key={key} /> )}
                </ul> : ''}
            </div>   
       </> : ''
    )
    
}
