import AppHeader from "../header";
import { Typography, Container} from '@material-ui/core';
import { getToken } from "../../../services/auth";
import NotAccess from "../../not-access";
import { useState, useEffect } from "react";
  
const Page = props => {
    
    const [cantView , setCantView] = useState(false);
    let {pageName='home', heading="", auth_req = false} = props;

    useEffect(() => {
        let isLoggedIn = getToken() || false;
        let cantViewVar = !isLoggedIn && auth_req ? true : false;
        setCantView(cantViewVar);
    } , [1])


    return cantView ? (
        <NotAccess /> 
    ) : <div className={`page ${pageName}`}>
        <AppHeader />
        {heading && <Container>
            <Typography variant="h6" component="h1">
                {heading}
            </Typography>
        </Container>}
        {props.children}    
    </div>
}
export default Page;