import { IconButton } from "@material-ui/core"
import { Play, Pause } from "react-feather"

const VideoButton = ({isPlaying = false , setPlaying}) => {
    return <span onClick={() => setPlaying ? setPlaying(!isPlaying) : ''}>
        { !isPlaying ? <IconButton className="buttonPlayPouse buttonPlay">
            <Play />
        </IconButton> : <IconButton className="buttonPlayPouse buttonPause">
            <Pause />
        </IconButton>}
    </span>
}
export default VideoButton;