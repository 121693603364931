import { Container, Typography, makeStyles, Box, ThemeProvider, createMuiTheme, Button, IconButton } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import Page from '../global/page'
import data from './singleContentData'
import dataCourses from './../courses/data';
import dataLesson from './../course/lessonData';
import { useParams, useHistory } from 'react-router-dom';
import { Copy, Play, X } from 'react-feather';
import LessonItem from '../LessonItem';
import ProgressLine from '../global/progress-line';
import VideoPlayer from '../courses/videoPlayer/index';
import FooterToast from '../global/FooterToast';
import { getCourseData, getLessonData } from '../../services/utils';
import axios from 'axios';
import globalVars from '../../consts/vars';
import {getToken} from '../../services/auth';
import LoadingPart from '../../components/global/loadingPart';
import { useSnackbar , closeSnackbar} from 'notistack';
import LessonState from './lesson-state';

const useStyles  = makeStyles({
    CourseName:{
        color:'#092f52',
        fontWeight:'bold',  
    },
    Icon:{
      border:'none',
      background:'none',
      width:'10px',
      height:'10px',
      border:'1px solid #000',
      borderRadius:'5px',
      padding:'4px',
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      marginRight:'8px',

    },
    LessonHeading:{
        fontSize:'14px',
        fontWeight:'bold',
        color:'#333',
    },
    LesssonContent:{
        fontSize:'12px',
        color:'#7f7f7f',
        lineHeight:'2',
        letterSpacing:'-0.36px',
    },
    ImageLesson: {
        marginTop: '24px',
        width: '100%',
        height: 'auto',
        borderRadius: '6px',
        marginBottom: '8px'
    },
    podcastHolder: {
        marginBottom: '48px'
    }
  });

  const theme = createMuiTheme({
    direction: 'ltr',
  });

export default function SingleLesson(props) {

    const classes = useStyles();
    const {course_id , lesson_id} = useParams();    
    const { enqueueSnackbar , closeSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(true);
    const [allres, setAllres] = useState({});
    const [course, setCourse] = useState( getCourseData(course_id , dataCourses) || {} );
    const [lesson, setLesson] = useState( getLessonData(lesson_id , dataLesson) || {} );
    const navigate = useHistory();
    const action = key => (
        <IconButton>
            <X color="#fff" size='16px' onClick={() => { closeSnackbar(key) }} />    
        </IconButton>  
    )

    const getLesson = () => {        
        setLoading(true);    

        axios({
            method: 'get',				            				
            url: `${globalVars.API_URL}/courses/${course_id}/lessons/${lesson_id}`,
            headers: {'Authorization' : `${getToken()}`}
        }).then( (res) => {    
            let {lesson = [], course = {}} = res.data;           
            setLoading(false);
            setCourse(course);
            setAllres(res.data);
            setLesson(lesson);
        }, (err) => {
            let {data = {}} = err.response;
            for (const [key, value] of Object.entries(data.errors)) {
                if ( key === 'Didnt buy' ) {
                    navigate.push(`/courses/${course_id}?dialog=should-buy-course`);
                    return false;                    
                } {
                    
                    enqueueSnackbar(value, {variant:'error' , action});
                    navigate.goBack();
                    return false;
                }                
            }               
        });             
    }     
  
    useEffect(() => {
        getLesson();
    } , [lesson_id])

    let {title = '', type} = course;
    let {image = ''} = course;
    let {video='',podcast = '', description = ''} = lesson;

    return (
        !loading ? <Page pageName="page single-lesson" auth_req={true}>
        <Container>
            <div className="heading-holder mb-16">
                <div className={classes.Icon}>
                    {type == 'video' ? <Play size={20} /> : <Copy size={20} />}
                </div>
                <Typography variant="h6" component="h2" className={classes.CourseName}>
                    {title}
                </Typography> 
            </div>
            <div className="lesson-item-holder">
                <div className="lesson-items">
                    <LessonItem item={lesson} className="mb-0" />
                </div>
            </div>
        </Container>   
        {course.type != 'card' ? <ProgressLine item={course} progress={0} /> : ''}
        <Container>
            {course.type == 'card' ? <LessonState item={allres} /> : ''}
            {image && !video ? <img src={image} alt='' className={classes.ImageLesson} /> : ''}
            {description ? <Box className="detail-holder" marginTop="16px">
                <div className="single-lesson-heading">
                    <Typography component="h2" className={classes.LessonHeading}>
                        محتوای جلسه
                    </Typography>
                </div>
                <div className="single-lesson-content">
                    <Typography componet="p" className={classes.LesssonContent}>
                        {description}
                    </Typography>
                </div>
            </Box> : ''}
            {video ? <Box className="video-holder" paddingTop="16px">
                <div className="single-lesson-heading">
                    <Typography component="h2" className={classes.LessonHeading}>
                        ویدیو کلاس 
                    </Typography>
                </div>  
                <div className="single-lesson-content">
                    <ThemeProvider theme={theme}>
                        <VideoPlayer videoUrl={video} pooster={image} />
                    </ThemeProvider>
                </div>
            </Box> : ''}
            {podcast ? <Box paddingBottom="16px" paddingTop="16px">
                <div className="single-lesson-heading">
                    <Typography component="h2" className={classes.LessonHeading}>
                        فایل صوتی
                    </Typography>
                </div>  
                <div className="single-lesson-content" style={{marginTop: '0'}}>
                    <ThemeProvider theme={theme}>
                        <VideoPlayer type='sound' videoUrl={podcast} />
                    </ThemeProvider>
                </div>
            </Box> : ''}
        </Container>
        {course.type == 'card' ? <FooterToast type='cards' title='لطفا پس از گوش دادن فایل صوتی، کارت ها را مشاهده نمایید' action={() => navigate.push(`/courses/${course_id}/lesson/${lesson_id}/cards`) } /> : ''}
        {course.type == 'video' ? <FooterToast type='exam' title='لطفا پس از اتمام کلاسدر خودآزمایی شرکت کنید' action={() => navigate.push(`/courses/${course_id}/lesson/${lesson_id}/exam`)} /> : ''}
    </Page> : <LoadingPart />
    )
}
