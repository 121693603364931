import React, { useState, useEffect } from 'react';
import Page from '../../global/page';
import { Container, Box, Fab } from '@material-ui/core';
import globalVars from '../../../consts/vars';
import { getToken } from '../../../services/auth';
import LoadingPart from '../../global/loadingPart';
import axios from 'axios';
import TicketRow from './ticket-row';
import { Plus } from 'react-feather';
import { useHistory } from 'react-router-dom';

function Tickets(props) {

    const navigate = useHistory();
    const [loading,setLoading] = useState(false);
    const [tickets,setTickets] = useState([]);

    const getTickets = () => {
        
        setLoading(true);
        let url = `${globalVars.API_URL}/tickets`;
        
        axios({
            method: 'get',				            				
            url,
            headers: {'Authorization' : `${getToken()}`}
        }).then( (res) => {    
            let {tickets = []} = res.data;        
            setTickets(tickets);
            setLoading(false);
        });    
             
            
    }

    useEffect(() => {
        getTickets();
    } , []);

    return (
        <Page className="tickets" heading="پشتیبانی">
            <Container>
                {loading ? <LoadingPart /> : <Box marginTop="8px">
                    {tickets.length ? tickets.map((item, key) => {
                        return <TicketRow item={item} />
                    }) : 'تیکتی تا کنون توسط شما ثبت نگردیده است.'}
                </Box> }
                <Fab color="primary" aria-label="add" style={{position: 'fixed',bottom: '16px',right: '16px'}} onClick={() => {
                    navigate.push('/profile/tickets/add');
                }}>
                    <Plus />
                </Fab>
            </Container>
        </Page>
    );
}

export default Tickets;