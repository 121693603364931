import { Grid, Typography } from "@material-ui/core";
import renderHTML from 'react-render-html';

const WordShape = ({type='' , content = {}, derivate = 1}) => {

    const Show = () => {
        console.log(content);
        switch(derivate) {
            case 1:
                let {n='',nMean='',nRepeat=''} = content;
                const col = type == 'Description' ? 12 : 6;
                return <Grid item xs={col} className={`word-shapes ${type}`}>
                        <div className="inner-card">
                            {type ? <div className="heading">
                                {type}
                            </div> : ''}
                            <div className="content">
                            {Object.keys(content).map((keyName, i) => (
                                content[keyName] ? <div>
                                    {renderHTML(content[keyName])}
                                </div> : ''
                            ))}
                            </div>
                        </div>
                    </Grid>;
            case 2:
                const colX = type == 'Description' ? 12 : 6;
                return <Grid item xs={colX} className={`word-shapes ${type}`}>
                            <div className="inner-card">
                                {type ? <div className="heading">
                                    {type}
                                </div> : ''}
                                <div className="content">
                                {content && <div>
                                    {renderHTML(content)}
                                </div>}
                                </div>
                            </div>
                        </Grid>;
        }
    }

    return Show();
}
export default WordShape;