import {Box, IconButton} from '@material-ui/core';
import LogoIelts from '../logo';
import IconMenu from '../../../assets/images/IconMenu.png';
import bgHeader from '../../../assets/images/headerShade.svg';
import ShortLink from './shortLink';
import Menu from './menu';
import { useState, useEffect } from 'react';
import { ChevronRight } from 'react-feather';
import { useHistory, useParams } from 'react-router-dom';
import { getToken } from '../../../services/auth';
import axios from 'axios';
import globalVars from '../../../consts/vars';
import { useDispatch, useSelector } from 'react-redux';
import actionGetSettings from '../../../actions/settings';

const AppHeader = () => {

    const [activeMenu, setActiveMenu] = useState(false);
    const [activeLayer, setActiveLayer] = useState(false);
    const [loadingSettings , setLoadingSettings] = useState(false);
    const [settings , setSettings] = useState(null);
    const dispatcher = useDispatch();
    const settingsR = useSelector(state => state.settings);

    const history = useHistory(); 

    const { course_id = null, lesson_id = null } = useParams();

    const BurgerMenu = burgerState =>{
        setActiveLayer(burgerState);
        setActiveMenu(burgerState);
    }

    const Back = () => {
        if ( lesson_id ) {
            history.push(`/courses/${course_id}`);
        } else if ( course_id && !lesson_id ) {
            history.push(`/`);
        } else {
            history.goBack();
        }
    }

    const getSettings = () => {
        setLoadingSettings(true);       
        axios({
            method: 'get',				            				
            url: `${globalVars.API_URL}/settings`,
            headers: {'Authorization' : `${getToken()}`}
        }).then( (res) => {    
            let {data = {}} = res;
            dispatcher(actionGetSettings(data));    
        });    
    }

    const isLoggedIn = getToken() || false;

    useEffect(() => {
        if ( isLoggedIn && !settingsR.pages )
            getSettings();
    } , [])

    return (
        <header className="app-header" >  
            <div className={`darklayer ${activeLayer ? 'active' : ''}`} onClick={() => BurgerMenu(false)  }></div>
            <img src={bgHeader} className="header-shade" />     
            <IconButton className="back-icon" onClick={() => Back()}>
                <ChevronRight />
            </IconButton>
            <LogoIelts />
            <IconButton className="burger-menu" onClick={() => BurgerMenu(true)}>
                <img src={IconMenu} />
            </IconButton>
            <Menu settings={settingsR} activeMenu={activeMenu} BurgerMenu={BurgerMenu} />          
            {isLoggedIn ? <ShortLink to='/profile/my-courses' title='دوره های من' /> : ''}
        </header>
    )
}
export default AppHeader;