import { Container } from '@material-ui/core'
import React from 'react'
import Page from '../global/page'
import { useSelector } from 'react-redux';
import renderHTML from 'react-render-html';


export default function ContactUs() {
    
    const settings = useSelector(state => state.settings);
    
    return (
        <Page className="contact-us" heading="تماس با ما">
            {settings.contactUs ? <Container>
                {renderHTML(settings.contactUs)}
            </Container> : ''}
        </Page>
    )
}
