
import { Container, Grid, Box, Typography } from '@material-ui/core';
import { useState, useEffect } from "react";
import CourseItem from './courseItem';
import data from './data';
import { getToken } from '../../services/auth';
import globalVars from '../../consts/vars';
import LoadingPart from '../global/loadingPart';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import Page from '../global/page';
import Categories from '../categories/index';

export default function CategoryCourses() {

    const [courses , setCourses] = useState([]);      
    const [categories , setCategories] = useState([]);        
    const [loading, setLoading] = useState(false);
    const {tag_id=null, category_id=null} = useParams();
    const getCourses = () => {
        
        setLoading(true);
        let url = `${globalVars.API_URL}/courses`;
        if ( tag_id )
            url += `?tag=${tag_id}`;
        if ( category_id )
            url += `?category=${category_id}`;
        axios({
            method: 'get',				            				
            url,
            headers: {'Authorization' : `${getToken()}`}
        }).then( (res) => {    
            let {courses = []} = res.data;           
            setCourses(courses);
            setLoading(false);
        });    
             
            
    }

    const getCategories = () => {
        
        setLoading(true);
        let url = `${globalVars.API_URL}/categories`;
        axios({
            method: 'get',				            				
            url,
            headers: {'Authorization' : `${getToken()}`}
        }).then( (res) => {    
            let {categories = []} = res.data;  
            setCategories(categories);
            setLoading(false);
        });    
            
    }
    

    useEffect(() => {
        getCourses();
        getCategories();
    } , [tag_id])

    
    const CourseNotFound = <Box display="flex" alignItems="center" justifyContent="center">                                    
            <Typography component="p">{`دوره ای یافت نشد.`}</Typography>
        </Box>

    return (
        <Page pageName="categoryCourses" auth_req={true}>
            <Categories />
            <Container>
                {!loading ? <>
                    {courses && courses.length ? <Grid className="courses" direction={'row'} container spacing={2}> 
                            {courses.map( (item , key) => <CourseItem item={item} key={key} /> )}
                        </Grid> : CourseNotFound}
                </> : <LoadingPart /> }
            </Container>
        </Page>
        
    )
}