import { Container, Typography , makeStyles, Grid, IconButton, CircularProgress, Box} from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { X, RotateCw } from 'react-feather'
import ProgressLine from '../global/progress-line'
import { useParams, useHistory } from 'react-router-dom'
import { Percentage } from '../../services/utils';
import WordShape from '../single-lesson/cards/shape'
import Navigation from '../single-lesson/cards/navigation'
import CardDetails from '../single-lesson/cards/details'
import BottomRate from '../single-lesson/cards/bottomRate';
import { useSnackbar } from 'notistack'
import { getToken } from '../../services/auth'
import globalVars from '../../consts/vars'
import axios from 'axios';
import renderHTML from 'react-render-html';
import LoadingPart from '../global/loadingPart'
import CardView from '../global/card-view'

const useStyles  = makeStyles({
    CardTopHeading:{
        fontSize:'18px',
        fontWeight:'bold',
        color:'#092f52',

    },
    CourseName:{
       fontSize:'12px' ,
       fontWeight:'300',
       color:'#7f7f7f',
    }
})

export default function CourseReviewCards() {

    const classes = useStyles();
    const {course_id} = useParams();
    const navigate = useHistory();
    const [infoModal, setInfoModal] = useState(false); 
    const [flipCard, setFlipCard] = useState(false);
    const [loading, setLoading] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const [cardIndex , setCardIndex] = useState(1);
    const [cards, setCards] = useState([]);
    const [course, setCourse] = useState({});

    const handleCloseInfo = () => {
        setInfoModal(false);
    }
  
    const handleOpenInfo = () => {
        setInfoModal(true);
    }

    const getCards = () => {        
        setLoading(true);    

        axios({
            method: 'get',				            				
            url: `${globalVars.API_URL}/courses/${course_id}`,
            headers: {'Authorization' : `${getToken()}`}
        }).then( (res) => {    
            let {hasReviewCards, reviewCards = [], course = {}} = res.data;  
            if ( hasReviewCards )
                setCards(reviewCards);      
            setCourse(course);
            setLoading(false);
        }, (err) => {
            let {data = {}} = err.response;
            for (const [key, value] of Object.entries(data.errors)) {
                enqueueSnackbar(value, {variant:'error'});
            }   
            navigate.goBack();
        });             
    }     
    
    useEffect(() => {
        getCards();
    } , []);

    useEffect(() => {
        setFlipCard(false);
    } , [cardIndex]);

    const targetCard  = cards && cards.length ? cards[cardIndex - 1] : {};

    return (
        !loading ? <div className="card-tour">
            <div className={`information ${infoModal ? 'active' : ''}`}>
                <div className="darklayer"></div>
                <div className="inner">
                    <Box display="flex" justifyContent="space-between" alignItems="center" className="heading">
                        <IconButton button onClick={handleCloseInfo}>
                            <X />
                        </IconButton>
                        <Typography>
                            Examples
                        </Typography>
                    </Box>
                    {targetCard && targetCard.information ? <Box className="content">
                        {renderHTML(targetCard.information)}
                    </Box> : ''}
                </div>
            </div>
            <div className="card-header">
                <div className="right-card-head">
                    <div className="top-heading">
                        <Typography component="h2" className={classes.CardTopHeading}>
                            کارت های 
                        </Typography>
                    </div>
                    <div className="course-name" >
                        <Typography component="span" className={classes.CourseName} >
                            {course.title}
                        </Typography>
                    </div>
                </div>
                <div className="left-card-head">
                    <button className="close" onClick={() => navigate.push(`/courses/${course_id}`)}>
                        <X />
                    </button>
                </div>
            </div>
            <ProgressLine progress={Percentage(cardIndex , cards.length || 0)}/>   
            {targetCard ? <Container className="navigation-conatiner">
                <Navigation totalCard={cards.length} cardIndex={cardIndex} setCardIndex={setCardIndex} />
                <Grid class={`cards-holder flip-card ${flipCard ? 'rotate-180' : ''}`} container spacing={1}>
                    <div class="flip-card-inner">
                    <div class="card-aspect flip-card-front">
                        <Typography component="h2" className="word">{targetCard.word}{`${targetCard.type ? '(' + targetCard.type + ')' : ''}`}</Typography>
                        <Typography component="h4"  className="intonation">{targetCard.phonetic}</Typography>
                        <IconButton className="flip-card-button" onClick={() => setFlipCard(!flipCard)}>
                            <RotateCw />
                        </IconButton>
                    </div>
                    <Grid className="card-aspect flip-card-back" >
                            <CardView item={targetCard} />
                            <CardDetails handleOpenInfo={handleOpenInfo} item={targetCard} />
                            <IconButton className="flip-card-button" onClick={() => setFlipCard(!flipCard)}>
                                <RotateCw />
                            </IconButton>
                    </Grid>
                    </div>
                </Grid>
                <BottomRate cardId={targetCard.id} totalCard={cards.length} cardIndex={cardIndex} setCardIndex={setCardIndex} />
            </Container> : ''}
        </div> : <LoadingPart />
    )
}
