import React, { useState, useRef, useEffect } from 'react'
import Page from '../global/page'
import { Container, Typography, makeStyles, Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'
import { Copy } from 'react-feather'
import ProgressLine from '../global/progress-line'
import ProgressNumber from '../global/progress-number'
import data from '../course/lessonData'
import reviewData from '../course/reviewData'
import dataCourses from './../courses/data'
import LessonItem from '../LessonItem'
import { useParams , useLocation } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import imageCourse from './data'
import FooterToast from '../global/FooterToast'
import { getCourseData } from '../../services/utils'
import axios from 'axios';
import globalVars from '../../consts/vars';
import {getToken} from '../../services/auth';
import { useSnackbar } from 'notistack'
import {getParameterByName} from '../../services/utils';

const useStyles  = makeStyles({
    CourseName:{
        color:'#092f52',
        fontWeight:'bold',  
    },
    Detail:{
      color:'#7f7f7f',
      fontSize:'12px',
      marginRight:'4px'
    },
    Icon:{
      border:'none',
      background:'none',
      width:'10px',
      height:'10px',
      border:'1px solid #000',
      borderRadius:'5px',
      padding:'4px',
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      marginRight:'8px',

    },
    FirsPrice:{
        fontSize:'14px',
        color:'#7f7f7f',
        textDecoration:'line-through'
    },
    CurrentPrice:{
        fontSize:'20px',
        color:'#333',
        marginRight:'8px',
    }
  });

function useQuery(key = '') {
  let location = useLocation().search;
  if ( key )  
    {      
      return getParameterByName(key , location );
    }
  return '';
}

export default function AddToCard({lessons = [] , course = {}}) {
	const classes = useStyles()
  const { course_id } = useParams();  
  const dialog = useQuery('dialog');
  const [openBuyDialog, setOpenBuyDialog] = useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [reviews] = useState(reviewData);  
  const buttonElGoPayment = useRef(null);
  const [paymentUrl, setPaymentUrl] = useState('/');

  let {title = '' , image = '' , desc = '', price = 0} = course;
  let {reviewAmount} = reviews;

  const addToCard = () => {    
    if ( course.price ) {
      setLoading(true);
      axios({
          method: 'post',				            				
          url: `${globalVars.API_URL}/courses/${course_id}/buy`,
          headers: {'Authorization' : `${getToken()}`}
      }).then( (res) => {    
          let {url=''} = res.data;  
          setPaymentUrl(url);   
          setTimeout(() => {
            if ( paymentUrl ) {
              buttonElGoPayment.current.click();             
            }  
            setLoading(false);
          } , 10);
      } , (err) => {
          let {data = {}} = err.response;
          for (const [key, value] of Object.entries(data.errors)) {
              enqueueSnackbar(value, {variant:'error'});
          }   
          setLoading(false);
          
      });  
    } else {
      enqueueSnackbar('خرید این دوره در حال حاضر غیر فعال است.', {variant:'error'});
    }
      
  }
  
  useEffect(() => {
    if ( dialog == 'should-buy-course' && !openBuyDialog) {
      setOpenBuyDialog(true);
    }
  } , [dialog])

  const handleBuyDialogClose = () => {
    setOpenBuyDialog(false);
  }

  return (
    <Page pageName=" page add-to-card">        
        <Dialog
              open={openBuyDialog}
              keepMounted
              onClose={handleBuyDialogClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
              className="dialogCloseExam dialogExamFinal"
          >
          <DialogTitle id="alert-dialog-slide-title">خریداری دوره</DialogTitle>
          <DialogContent>                                
              <DialogContentText id="alert-dialog-slide-description">                
                  <Typography component="p">برای مشاهده جلسات، دوره را خریداری نمایید.</Typography>
              </DialogContentText>
          </DialogContent>
          <DialogActions>    
              <Button className="continue" onClick={() => addToCard()} color="primary">
                  {loading ? <CircularProgress style={{color: '#fff'}} size={16} /> : course.price ? 'خرید این دوره' : 'به زودی'}
              </Button>
              <Button className="continue" onClick={() => handleBuyDialogClose()} color="secondary" style={{backgroundColor: '#fd98aa'}}>
                بستن
              </Button>
          </DialogActions>
      </Dialog>
      <Container maxWidth="xs">
          <div className="heading-holder">
            <Typography variant="h6" component="h2" className={classes.CourseName}>
                {title}
            </Typography> 
          </div>
          <div className="course-detail mt-8 mb-8">
              <div className="course-image"  style={{backgroundImage:`url('${image}')`}} ></div>
          </div>
          <div className="lesson-item-holder pb-60">{lessons && lessons.length ? <ul className="lesson-items">
            {lessons.map( (item , key) => <LessonItem key={key} item={item} index={key} hasStatus={true} course={course} />)}
            </ul> : ''} </div>
      </Container>      
      <a href={paymentUrl} style={{display: 'none'}} target="_blank" ref={buttonElGoPayment}></a>
      {parseInt(course.price) ? <FooterToast title={loading ? <CircularProgress style={{color: '#fff'}} size={16} /> : 'خرید این دوره'} type='buy' price={price} action={addToCard} /> : ''}
    </Page>
  )
}