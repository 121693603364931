export default [
    {
        id: 1,
        title: 'همه دسته بندی ها',
        link: 'all',
        active:'true',
    },
    {
        id: 2,
        title: 'کارت های آموزشی',
        link: 'cards'
    },
    {
        id: 3,
        title: 'IELTS General',
        link: 'cards'
    },
    {
        id: 4,
        title: 'IELTS Academy',
        link: 'cards'
    }
]