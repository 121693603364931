import React, { useState, useEffect } from 'react';
import Page from '../../global/page';
import { Container, Box, makeStyles, Typography } from '@material-ui/core';
import globalVars from '../../../consts/vars';
import { getToken } from '../../../services/auth';
import LoadingPart from '../../global/loadingPart';
import axios from 'axios';
import { convertDate, convertFA } from '../../../services/utils';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    ticketRow: {
        cursor: 'pointer',
        position:'relative',
        padding: '16px',
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.12)',
        marginBottom: '16px',
        transition: '0.2s all',
        '&:hover': {
            boxShadow: '0 2px 16px rgba(0,0,0,0.12)'
        }
    },
    subject: {
        marginBottom: '16px'
    },
    status: {
        '& > strong': {
            fontWeight: 'bold'
        },
        '& > span': {
            marginLeft: '8px',
            color: 'rgba(0,0,0,0.43)'
        },
    },
    createdAt: {
        position: 'absolute',
        top: '16px',
        right: '16px'
    }
  }));

function TicketRow(props) {

    const navigate = useHistory();
    const classes = useStyles();
    let {item = {}} = props;

    return (
        <Box className={classes.ticketRow} onClick={() => {
            navigate.push(`/profile/tickets/${item.id}`)
        }}>
            <Box className={classes.subject}>
                {item.subject}
            </Box>
            {item.status ? <Box className={classes.status}>
                <Typography component="strong">وضعیت تیکت:</Typography>
                <Typography component="span">{convertFA(item.status)}</Typography>
            </Box> : ''}
            {item.priority ? <Box className={classes.status}>
                <Typography component="strong">الویت:</Typography>
                <Typography component="span">{convertFA(item.priority)}</Typography>
            </Box> : ''}
            <Box className={classes.createdAt}>
                {convertDate(item.created_at)}
            </Box>
        </Box>
    );
}

export default TicketRow;