import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';

const useStyles  = makeStyles({
    ProgressStateHolder: {
        marginBottom: '48px',
        '&.type-vertical': {
            position: 'relative',
            minHeight: '100px',
            justifyContent: 'center',
            alignItems: 'flex-end',
        }
    },
    ProgressState:{
        textAlign: 'center',
        backgroundColor: '#fafafa',
        '&.type-horiz': {
            minWidth: '80px',
            height: '5px',
        },
        '&.type-vertical': {
            width: '20px',
            '& *': {
                transform: 'rotate(90deg)',
                position: 'relative',
                top: '0',
                right: '24px',
                margin: 0,
                padding: 0
            }
        },
        '&.hard': {
            backgroundColor: 'red',
            '& *': {
                color: 'red'
            }
        },
        '&.easy': {
            backgroundColor: 'green',
            '& *': {
                color: 'green'
            }
        },
        '&.medium': {
            backgroundColor: 'purple',
            '& *': {
                color: 'purple'
            }
        },
        '&.unAnswered': {
            backgroundColor: 'orange',
            '& *': {
                color: 'orange'
            }
        }
    },
    ProgressStateLabel: {
        marginTop: '8px',
        padding: '0 8px'
    }
})


function LessonState(props) {

    let { item = {}, type = 'horiz' } = props;

    const classes = useStyles();

    const ProgressState = ({state = '' , percent = 0, item = {}}) => {

        let stateFA = state;
        switch(state) {
            case 'hard':
                stateFA = 'سخت';
                break;
            case 'easy':
                stateFA = 'آسان';
                break;
            case 'medium':
                stateFA = 'متوسط';
                break;
            case 'unAnswered':
                stateFA = 'بدون پاسخ';
                break;
            default:
                stateFA = 'نامعلوم'
        }

        var outOff = 200;
        var value = percent;
        var result = (value * 400) / outOff;

        return (
            type == 'horiz' ? <Box className={`progress-state type-${type} ${classes.ProgressState} ${state}`} style={{width: `${percent}%`}}>
                <Typography className={classes.ProgressStateLabel}>{`${percent}% ${stateFA}`}</Typography>
            </Box> : <Box position="relative" display="flex" justifyContent="flex-end" flexDirection="column" width="50px" height="200px" marginLeft="48px">
                <div className={`progress-state ${classes.ProgressState} type-${type} ${state}`} style={{ width: '35px', height: `${result}px`}}></div>
                <Typography className={classes.ProgressStateLabel} style={{transform: 'rotate(90deg)', position: 'absolute',left: 24, whiteSpace: 'nowrap' ,bottom: 24}}>{`${percent}% ${stateFA}`}</Typography>
            </Box>
        )
    }

    return (
        <Box className={`progress-state-holder ${classes.ProgressStateHolder} type-${type}`} display="flex" margin="0 -24px 24px">
            {item.easy ? <ProgressState state='easy' item={item} percent={item.easy} /> : ''}
            {item.hard ? <ProgressState state='hard' item={item} percent={item.hard} /> : ''}
            {item.medium ? <ProgressState state='medium' item={item} percent={item.medium} /> : ''}
            {item.unAnswered ? <ProgressState state='unAnswered' item={item} percent={item.unAnswered} /> : ''}
        </Box>
    );
}

export default LessonState;