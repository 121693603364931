import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import VideoControls from './controls';
import VideoProgress from './progress';
import screenfull from "screenfull";

const VideoPlayer = ({videoUrl='' , pooster='', type='video'}) => {
    const reactPlayer = useRef(null);
    const [isPlaying, setPlaying] = useState(false);
    const [progressState, setProgressState] = useState({});
    const [videoDuration, setVideoDuration] = useState(0);
    const [loading, setLoading] = useState(false);
    const [fullscreen, setFullscreen] = useState(false);

    const progress = ({playedSeconds='' , played='', loadedSeconds='',loaded=''}) => {
        setProgressState({
            playedSeconds,
            played,
            loadedSeconds,
            loaded
        })
    }
    
    useEffect(() => {
        if (fullscreen) {
          try {
            const videoElem = reactPlayer.current.getInternalPlayer();
            screenfull.request(videoElem);
          } catch(e) {
              
          }
        }
    }, [fullscreen]);

    return (
        <>
            <div className="videoPlayer">
                {type == 'video' && reactPlayer.current && <VideoControls isLoading={loading} reactPlayer={reactPlayer} setPlaying={setPlaying} isPlaying={isPlaying} pooster={pooster} />}
                <ReactPlayer ref={reactPlayer} fullscreen={true} onBuffer={() => setLoading(true)} onBufferEnd={() => setLoading(false)} onDuration={(duration) => setVideoDuration(duration)} onProgress={progress} playing={isPlaying} width="100%" height="auto" style={{display: 'flex'}} url={videoUrl} />
            </div>
            {reactPlayer.current && <VideoProgress type={type} reactPlayer={reactPlayer} videoDuration={videoDuration} progressState={progressState} fullscreen={fullscreen} setFullscreen={setFullscreen} setPlaying={setPlaying} isPlaying={isPlaying}  />}
        </>
    )
}
export default VideoPlayer;