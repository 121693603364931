import { List, ListItem, Avatar , ListItemText, Typography, makeStyles } from '@material-ui/core';
import { useState } from "react";
import React from 'react'
import dataLogin from './dataLogin';
import dataLogout from './dataLogout';
import { Instagram, Send, X } from 'react-feather';
import { Link } from 'react-router-dom';
import { getProfile, getToken, logOut } from '../../../services/auth';
import {useHistory} from 'react-router-dom';

const useStyles  = makeStyles({
    Name:{
        fontSize:'18px',
        fontWeight:'bold',
        color:'#092f52',
        marginBottom:'8px'
    },
    Phone:{
        fontSize:'12px',
        fontWeight:'300',
        color:'#7f7f7f',

    },
    Avatar:{
        borderRadius:'15px',
        border:'1px solid #fff',
    },
    MenuItem:{
        fontSize:'18px',
        fontWeight:'bold',
        color:'#333333',
    },
    ContactUs:{
        fontSize:'18px',
        fontWeight:'bold',
        color:'#333333',
    },
    ListItem:{
        paddingRight:'24px',
        paddingLeft:'24px',
    }
  });

export default function Menu(props) {

    const {settings = {}} = props;
    const classes = useStyles();    
    const isLoggedIn = getToken() || false;
    const [menuitems] = useState( (isLoggedIn ? dataLogin : dataLogout) || [] );

    const navigate = useHistory();     
    const profile = getProfile() || {};    
    const { name = '' , mobile = '', profile_photo_url = ''} = profile;

    return (
        <div className={`menuholder ${props.activeMenu ? 'active' : ''}`} >
            {isLoggedIn ? <div className="header-menu">
                <Avatar alt={name} src={profile_photo_url} className={classes.Avatar}></Avatar>
                {name || mobile ? <div className="your-information"> 
                    {name ? <Typography className={classes.Name} component="p">{name}</Typography> : ''}
                    {mobile ? <Typography className={classes.Phone} component="p">{mobile}</Typography> : ''}
                </div> : ''}
                <button className="close-menu" onClick={() => props.BurgerMenu(false)}>
                   <X/>
                </button>
            </div> : ''}
            {menuitems && menuitems.length ?<List component="nav" aria-label="">{menuitems.map( (item , key) => <ListItem button onClick={() => !item.link ? item.action ? item.action() : () => console.log('no action') : navigate.push(item.link)}>
                    <ListItemText primary={item.menuitem} align="right" classes={{primary:classes.MenuItem}} item={item} key={key} /> 
                </ListItem> )}
                {(settings && settings.pages && typeof(settings.pages) == 'object') && Object.entries(settings.pages).map((item , key) => {
                    return item[1] ? <ListItem button>
                        <a href={item[1].url} title={item[1].name} target='_blank'><ListItemText primary={item[1].name} align="right" classes={{primary:classes.MenuItem}} item={item[1]} key={key} /> </a>
                    </ListItem> : ''
                })}
                {isLoggedIn ? <ListItem button onClick={logOut}>
                        <ListItemText primary={'خروج'} align="right" classes={{primary:classes.MenuItem}} />
                    </ListItem> : ''}
            </List> : ''}
            <div className="footer-menu">
                <div className="contact-us">
                <Typography  component="p">
                    <Link to="/contactus" className={classes.ContactUs}>
                        تماس با ما
                    </Link>
                </Typography>
                </div>
                {settings && settings.social ? <div className="social-media">
                   <div className="telegram">
                    {settings.social.telegram ? <a title="Telegram" target='_blank' href={settings.social.telegram}>
                        <Send/>
                    </a> : ''}
                   </div>
                   <div className="instagram">
                    {settings.social.instagram ? <a title="Instagram" target='_blank' href={settings.social.instagram}>
                    <Instagram/>
                        </a> : ''}
                   </div>
                </div> : ''}
            </div>
        </div>
    )
}
