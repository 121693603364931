import { IconButton, Typography } from "@material-ui/core"
import { ChevronLeft, ChevronRight } from "react-feather"

const Navigation = ({cardIndex = 0, setCardIndex , totalCard = 0}) => {
    return <div className="navigation">
        <IconButton>
            <ChevronRight size={32} onClick={() => cardIndex < totalCard ? setCardIndex(cardIndex+1) : setCardIndex(totalCard)} />
        </IconButton>
        <div className="nums">
            <Typography className="total">{totalCard}</Typography>
            <Typography className="divider">/</Typography>
            <Typography className="current">{cardIndex}</Typography>
        </div>
        <IconButton>
            <ChevronLeft size={32} onClick={() => cardIndex > 1 ? setCardIndex(cardIndex-1) : setCardIndex(1)} />
        </IconButton>
    </div>
}
export default Navigation;