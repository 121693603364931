import { IconButton, Modal, Backdrop, makeStyles, Fade, Box, Typography, Button } from "@material-ui/core"
import { Volume2, X } from "react-feather"
import fx1 from './../../../assets/fx/hollow-582.mp3';
import fx2 from './../../../assets/fx/insight-578.mp3';
import UIfx from 'uifx';
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  inner: {
    backgroundColor: '#fff'
  }
}));

const CardDetails = ({item = {} , handleOpenInfo}) => {
  
    const classes = useStyles();

    let bell1 = null;
    let bell2 = null;
    try {
      bell1 = new UIfx(
          item.britishPron,
          {
            volume: 1,
            throttleMs: 100
          }
        )
      bell2 = new UIfx(
          item.americanPron,
          {
            volume: 1,
            throttleMs: 100
          }
        )
    } catch {

    }    
    
    return <div className="bottom-card-detail">        
        <Button className="info-button" onClick={handleOpenInfo}>
          <Typography component="span">Examples</Typography>
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                <path fill="#fff" d="M-8761.314 1031a5.688 5.688 0 0 1-5.687-5.687v-14.625a5.688 5.688 0 0 1 5.688-5.687h14.625a5.687 5.687 0 0 1 5.688 5.688v14.625a5.687 5.687 0 0 1-5.687 5.688zm-4.062-20.312v14.625a4.062 4.062 0 0 0 4.063 4.063h14.625a4.062 4.062 0 0 0 4.063-4.062v-14.625a4.062 4.062 0 0 0-4.062-4.062h-14.625a4.062 4.062 0 0 0-4.065 4.061zm4.875 14.625a.813.813 0 0 1-.812-.812v-1.625a.813.813 0 0 1 .813-.812h1.625a.811.811 0 0 1 .813.813v1.625a.812.812 0 0 1-.812.813zm4.063-.812v-1.625h9.75v1.625zm-4.062-4.875a.813.813 0 0 1-.812-.812v-1.625a.813.813 0 0 1 .813-.812h1.625a.811.811 0 0 1 .813.813v1.625a.812.812 0 0 1-.812.813zm4.063-.812v-1.625h9.75v1.625zm-4.062-4.875a.813.813 0 0 1-.812-.812v-1.627a.813.813 0 0 1 .813-.812h1.625a.811.811 0 0 1 .813.813v1.625a.812.812 0 0 1-.812.813zm4.063-.812v-1.627h9.75v1.625z" transform="translate(8767.002 -1005)"/>
            </svg>            
        </Button>
        {item.britishPron ? <IconButton onClick={() => bell1 && bell1.play()} className='britishPron'>
            <Volume2 size={26} />
        </IconButton> : ''}
        {item.americanPron ? <IconButton onClick={() => bell2 && bell2.play()} className='americanPron'>
            <Volume2 size={26} />
        </IconButton> : ''}
    </div>; 
}
export default CardDetails;