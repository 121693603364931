import React from 'react';
import { Box, makeStyles, Typography, Button } from '@material-ui/core';
import { AlertTriangle } from 'react-feather';
import Page from '../global/page';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    IconHolder: {
    }
  }));

function NotAccess({title='شما به این صفحه دسترسی ندارید'}) {
    const classes = useStyles();
    const navigate = useHistory();
    return (
        <Page>
            <Box>            
                <Box width='100%' display="flex" alignItems="center" justifyContent="center" padding="24px 0" className={classes.IconHolder}>
                    <AlertTriangle size='64px' />   
                </Box>
                <Box width='100%' display="flex" alignItems="center" justifyContent="center">
                    <Typography component="p" variant="h6">{title}</Typography>
                </Box>
                <Box width='100%' display="flex" alignItems="center" marginTop="24px" justifyContent="center">
                    <Button variant="contained" className='button-submit' onClick={() => {
                        navigate.push('/');
                    }}>
                        بازگشت به صفحه اصلی
                    </Button>
                </Box>
            </Box>
        </Page>
    );
}

export default NotAccess;