import { Link } from "react-router-dom";

const ShortLink = props => {
    let {to='' , title=''} = props;
    return (
        <div className="short-link">
            <Link to={to}>{title}</Link>
        </div>
    )
}
export default ShortLink;