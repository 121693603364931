import { Link, useParams } from "react-router-dom";

export default function TagItem(props) {
    let {item = {}} = props;
    let {name = '', id} = item;
    const {tag_id = null} = useParams();
    return (
        <li className={`category-item ${tag_id == id ? 'active' : ''}`}>
            <Link to={`/courses/tag/${name}/${id}`}>
                {name}
            </Link>
        </li>
    )
}