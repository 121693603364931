
import './assets/styles/main.scss';
import './assets/styles/components-mamz.scss';
import './assets/styles/components.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import SplashScreen from './components/splash';
import HomeComponent from './components/home';
import Container from '@material-ui/core/Container';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import Profile from './components/profile';
import MyCards from './components/profile/my-cards';
import MyCourses from './components/profile/my-courses';
import CourseSingle from './components/course';
import SingleLesson from './components/single-lesson';
import 'react-circular-progressbar/dist/styles.css';
import Register from './components/register';
import RTL from './RTL';
import Login from './components/login';
import AddToCard from './components/add-to-card';
import LessonCards from './components/single-lesson/cards';
import LessonExam from './components/single-lesson/exam';
import { SnackbarProvider } from 'notistack';
import { getToken } from './services/auth';
import CourseReviewCards from './components/course/courseReviewCards';
import TagComponent from './components/tag';
import NotAccess from './components/not-access';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import reducer from './reducers';
import Faqs from './components/faqs';
import CategoryCourses from './components/courses/category-courses';
import AboutUs from './components/about-us';
import Tickets from './components/profile/tickets';
import TicketSingle from './components/profile/tickets/ticket-single';
import TicketAdd from './components/profile/tickets/ticket-add';
import ContactUs from './components/contact-us';

const useStyles = makeStyles((theme) => ({
  AppParent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    position: 'fixed',
    top:'50%',
    left:'50%',
    transform: 'translate(-50%,-50%)',
    width: '450px',
    maxWidth: '100%'
  },
  parentContainer: {
    padding: 0,
    backgroundColor: '#ffffff',
    height:'100vh',
    maxHeight: '800px',
    overflowY:'auto',
    borderRadius: '16px',
    maxWidth: '100%',
    width: '100%',
    maxWidth:'98%',
    boxShadow: '0 6px 26px rgba(0,0,0,0.06)',
    overflow: 'hidden'
  },
  '@media (max-width: 992px)': {
    AppParent: {
      transform: 'none',
      top:0,
      left: 0,
      position: 'relative',
      height: '100%',
      margin: '0 auto'
    },
    parentContainer: {
      borderRadius: '0',
      maxWidth: '100%'
    }
  },
}));

function App() {

  const store = createStore(reducer);
  const classes = useStyles();
  const theme = createMuiTheme({
    direction: 'rtl',
    pallete: {
      primary: {
        main: '#092f52'
      },
      secondary: {
        main: '#fbc10e'
      }
    },
    overrides: {
      MuiOutlinedInput: {
          root: {
              position: 'relative',
              '& $notchedOutline': {
                  borderColor: 'rgba(0, 0, 0, 0.23)',
              },
              '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
                  borderColor: '#092f52',
                  // Reset on touch devices, it doesn't add specificity
                  '@media (hover: none)': {
                      borderColor: 'rgba(0, 0, 0, 0.23)',
                  },
              },
              '&$focused $notchedOutline': {
                  borderColor: '#092f52',
                  borderWidth: 1,
              },
          },
      },
      MuiFormLabel: {
            padding:' 0 8px',
            backgroundColor:'#fff',
            fontFamily:'iransans',
          root: {
              '&$focused': {
                  color: '#092f52'
              }
          }
      }
      
    }
  });

  return (
    <div className={classes.AppParent}>   
      <Provider store={store}>
        <RTL>
          <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3} hideIconVariant={true}>
                <Container maxWidth="xs" className={classes.parentContainer} id="app-container">        
                    <Router basename={'/'}>
                      <Switch>                      
                        <Route exact path="/">                        
                          {!getToken() ? <SplashScreen /> : <HomeComponent />}
                        </Route>
                        <Route exact path="/register">
                          <Register />
                        </Route>
                        <Route exact path="/profile">
                          <Profile />
                        </Route>
                        <Route exact path="/profile/my-courses">
                          <MyCourses />
                        </Route>
                        <Route exact path="/courses/category/:tax_name/:category_id">
                          <TagComponent />
                        </Route>
                        <Route exact path="/courses/category/:tax_name/:category_id/courses">
                          <CategoryCourses />
                        </Route>
                        <Route exact path="/courses/tag/:tax_name/:tag_id">
                          <TagComponent />
                        </Route>
                        <Route exact path="/courses">
                          List Courses
                        </Route>
                        <Route exact path="/courses/:course_id/lesson/:lesson_id">
                          <SingleLesson />
                        </Route>
                        <Route exact path="/courses/:course_id/lesson/:lesson_id/cards">
                          {getToken() ? <LessonCards /> : <NotAccess />}
                        </Route>
                        <Route exact path="/courses/:course_id/lesson/:lesson_id/exam">                      
                          {getToken() ? <LessonExam /> : <NotAccess />}
                        </Route>
                        <Route exact path="/profile/tickets">                      
                          <Tickets />
                        </Route>
                        <Route exact path="/profile/tickets/add">                      
                          <TicketAdd />
                        </Route>
                        <Route exact path="/profile/tickets/:ticket_id">                      
                          <TicketSingle />
                        </Route>
                        <Route exact path="/login">
                          <Login/>
                        </Route>
                        <Route exact path="/about-us">
                          <AboutUs />
                        </Route>
                        <Route exact path="/contact-us">
                          <ContactUs />
                        </Route>
                        <Route exact path="/faqs">
                          <Faqs />
                        </Route>
                        <Route exact path="/courses/:course_id">
                          <CourseSingle />
                        </Route>
                        <Route exact path="/courses/:course_id/review-cards">
                          {getToken() ? <CourseReviewCards /> : <NotAccess />}
                        </Route>
                      </Switch>
                    </Router>
                </Container> 
              </SnackbarProvider>
          </ThemeProvider>    
        </RTL>   
      </Provider>      
    </div>
  );
} 

export default App;
