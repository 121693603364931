import { IconButton } from "@material-ui/core"
import { Play, Pause } from "react-feather"
import VideoButton from "./button"

const VideoControls = ({reactPlayer = {} , pooster='', setPlaying , isPlaying=false, isLoading = false}) => {
    return <div className="video-controls">
       {reactPlayer.current.getCurrentTime() === 0 ? <div className={`pooster ${isPlaying ? 'hide' : ''}`} style={{backgroundImage: `url(${pooster})`}}></div> : ''}
        <div className={`hoverDetails ${isPlaying ? 'showOnHover' : ''}`} onClick={() => setPlaying(!isPlaying)}>
            <div className="buttonActionHolder">
                <VideoButton isPlaying={isPlaying} />
            </div>
        </div>
    </div>
}
export default VideoControls;