import React from 'react';
import { Typography, Radio, RadioGroup, FormControlLabel } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { plusZero, scrollTo } from '../../../services/utils';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    question: {
        marginTop: '32px'
    },
    heading: {
        display: 'flex',
        alignItems: 'center'
    },
    headingIndex: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#ffffff',
        width: '32px',
        height: '32px',
        borderRadius: '10px',
        border: 'solid 1px #ffffff',
        backgroundColor: '#e1e5f3',
        marginRight: '8px'
    },
    ListItemIcon: {
        minWidth: 'initial',
        marginLeft: '-16px'
    },
    currentAnswer: {
        fontSize: '14px',
        fontWeight: 'bold'
    },
    answer: {
        fontSize: '14px',
        fontWeight: '200'
    },
    title: {
        direction: 'rtl'
    }
}));
const Question = ({item = {}, index = 0, handleAnswer}) => {
    const classes = useStyles();
    const [value, setValue] = React.useState();
    const handleChange = (event) => {
      scrollTo(`question-${index + 2}`);
      setValue(event.target.value);
      handleAnswer(event.target.value , index);
    };
    let {options = [] , title= ''} = item;
    return <div className={`question ${classes.question}`} id={`question-${index}`}>
        <div className={classes.heading}>
            <Typography className={classes.headingIndex}>{plusZero(index)}</Typography>
            <Typography className={`title ${classes.title}`}>{title}</Typography>
        </div>
        <div className="items">
            <RadioGroup aria-label="question" name="question" value={value} onChange={handleChange}>
                {options.map((itemValue , key) => {
                    let label = '';
                    let val = '';
                    {Object.keys(itemValue).map((keyName, i) => {
                        label = itemValue[keyName];
                        val = item.id + '-' + parseInt(keyName);
                    })}
                    return (
                    <FormControlLabel key={key} value={val} control={<Radio color="primary" style={{color: val == value ? '#38c883' : '#7f7f7f'}} />} label={<Typography className={val == value ? classes.currentAnswer : classes.answer}>
                        {label}
                    </Typography>} />
                    );
                })}
            </RadioGroup>     
        </div>
    </div>
}
export default Question;