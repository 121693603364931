import React from "react";
import { Grid } from "@material-ui/core";
import WordShape from "../../single-lesson/cards/shape";

function CardView(props) {
  const { item = {} } = props;
  const { derivativeType = 1 } = item;

  const getCardView = () => {
    switch (derivativeType) {
      case 1:
        return (
          item.derivative && (
            <Grid className="shaps-holder" container spacing={1}>
              {item.derivative[0] && item.derivative[0]["nMean"] && (
                <WordShape type="Noun" content={item.derivative[0]} />
              )}
              {item.derivative[1] && item.derivative[1]["vMean"] && (
                <WordShape type="Verb" content={item.derivative[1]} />
              )}
              {item.derivative[2] && item.derivative[2]["adjMean"] && (
                <WordShape type="Adjective" content={item.derivative[2]} />
              )}
              {item.derivative[3] && item.derivative[3]["advMean"] && (
                <WordShape type="Adverb" content={item.derivative[3]} />
              )}
            </Grid>
          )
        );
      case 2:
        return (
          item.derivative2 && (
            <Grid className="shaps-holder derivative-two" container spacing={1}>
              {item.derivative2[0] && item.derivative2[0]["synAnt"] && (
                <WordShape
                  derivate={2}
                  type="Synonyms / Antonyms"
                  content={item.derivative2[0]["synAnt"]}
                />
              )}
              {item.derivative2[0] && item.derivative2[0]["meaning"] && (
                <WordShape
                  derivate={2}
                  type="Meaning"
                  content={item.derivative2[0]["meaning"]}
                />
              )}
              {item.derivative2[0] && item.derivative2[0]["description"] && (
                <WordShape
                  derivate={2}
                  type="Description"
                  content={item.derivative2[0]["description"]}
                />
              )}
            </Grid>
          )
        );
      default:
        return " ";
    }
  };

  return getCardView() || " ";
}

export default CardView;
