import { Container } from '@material-ui/core'
import React from 'react'
import Page from '../global/page'
import { useSelector } from 'react-redux';
import renderHTML from 'react-render-html';


export default function AboutUs() {
    
    const settings = useSelector(state => state.settings);
    
    return (
        <Page className="about-us" heading="درباره ما">
            {settings.aboutUs ? <Container>
                {renderHTML(settings.aboutUs)}
            </Container> : ''}
        </Page>
    )
}
