import Logo from '../../assets/images/logo.png';
import { Link, useHistory } from 'react-router-dom';

const LogoIelts = props => {
    const navigate = useHistory();
    let {to=''} = props;
    return <div onClick={() => navigate.push(to)} className="logoIlets">
        <img src={Logo} className="main-logo" />
    </div>
}
export default LogoIelts;