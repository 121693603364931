import { Typography } from "@material-ui/core"

const Tabs = ({ activeTab='myCourses' , setActiveTab}) => {
    return <div className={`tabs`}>
        <div className={`tabItem ${activeTab == 'myCourses' ? 'active' : ''}`} onClick={() => setActiveTab('myCourses')}>
            <Typography>
                دوره های من
            </Typography>
        </div>
        <div className={`tabItem ${activeTab == 'myCards' ? 'active' : ''}`} onClick={() => setActiveTab('myCards')}>
            <Typography>
                کارت های من
            </Typography>
        </div>
    </div>
}
export default Tabs;