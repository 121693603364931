import LogoIelts from "../global/logo"
import {Typography,  Button, Box} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {ArrowLeft} from 'react-feather';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    SplashButtonHolder: {
        position: 'absolute',
        bottom:'80px',
        left: '50%',
        transform:'translateX(-50%)'
    },
    splashButton: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius:'25px',
        border: '2px solid #1a4874',
        width: '180px',
        maxWidth: '100%',
        color: '#1a4874',
        height: '50px',
        "&:hover": {
            color: '#ffffff',
            backgroundColor: '#1a4874'
        },
        boxShadow: '0 5px 20px 0 rgba(26, 72, 116, 0.2);'
    },
    splashContainedButton: {
        position: 'relative',
        display: 'flex',
        borderRadius:'25px',
        border: '2px solid #1a4874',
        width: '180px',
        maxWidth: '100%',
        color: '#ffffff',
        "&:hover": {
            color: '#1a4874',
        },
        backgroundColor: '#1a4874',
        marginTop: '28px',
        height: '50px',
        boxShadow: '0 5px 20px 0 rgba(26, 72, 116, 0.2);'
    },
    '@media (max-width:400px)': {
        SplashButtonHolder : {
            bottom: '24px'
        }
    }
  }));
const SplashScreenButton = () => {
    const classes = useStyles();
    const navigate = useHistory();
    return (
        <Box className={classes.SplashButtonHolder} display="flex" flexDirection="column">
            <Button variant="outlined" endIcon={<ArrowLeft className={classes.endIcon} />} className={classes.splashButton} onClick={() => navigate.push('/login')}>
                <Typography variant="h5" component="span">ورود</Typography>
            </Button>
            <Button className={classes.splashContainedButton}>
                <Typography variant="h5" component="span" onClick={() => navigate.push('/register')}>عضویت</Typography>
            </Button>
        </Box>
    )
}
export default SplashScreenButton;