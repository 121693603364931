export default [
    {
        id: 1,
        image:'/static/images/a1@3x.jpg',
        title: 'دوره کامل IELTS',
        desc:'128 کارت آموزشی',
        totalCard:2000,
        isBought: true,
        type: 'cards'
    },
    {
        id: 2,
        image:'/static/images/a2@3x.jpg',
        title: 'دوره جامع زبان دبیرستان',
        desc:'18 ساعت ویدیو آموزش',
        totalCard:2000,
        isBought: false,
        type: 'videos'
    },
    {
        id: 3,
        image:'/static/images/a3@3x.jpg',
        title: 'دوهزار کلمه پرکاربرد',
        desc:'18 ساعت ویدیو آموزش',
        totalCard:2000,
        isBought: true,
        type: 'videos'
    },
    {
        id: 4,
        image:'/static/images/a4@3x.jpg',
        title: 'دوره کامل مکالمه انگلیسی',
        desc:'18 ساعت ویدیو آموزش',
        totalCard:2000,
        isBought: false,
        type: 'videos'
    }
]