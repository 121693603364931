import React, { useEffect } from 'react';
import Page from "../global/page";
import axios from 'axios';
import globalVars from "../../consts/vars";
import { useSnackbar } from "notistack";
import { TextField, Button, Box, Typography, Fab } from "@material-ui/core";
import LoadingPart from "../global/loadingPart";
import { getToken, getProfile, setProfile } from "../../services/auth";
import SearchBox from "../global/searchBox";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { ChevronDown, Plus } from 'react-feather';

const Accordion = withStyles({
    root: {
      marginBottom: '16px !important',
      borderRadius: '25px',
      border: '1px solid #1a4874',
      boxShadow: 'none',
      overflow: 'hidden',
      '&:not(:last-child)': {
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
root: {
    borderRadius: '25px',
    backgroundColor: 'transparent',
    marginBottom: -1,
    minHeight: 56,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .iconDropdown': {           
       transition: '0.3s all',
       position: 'relative',
       top: '3px'
     },
    '&$expanded': {
        minHeight: 56,
        borderRadius:'0',
        borderBottom: '1px solid #1a4874',        
        '& .iconDropdown': {           
           transform: 'rotate(180deg)',
           top: '-3px'
        }
    },
},
content: {
    '&$expanded': {
        color: '#1a4874',
        margin: '12px 0'
    },
},
expanded: {},
})(MuiAccordionSummary);

const useStyles  = makeStyles({
    paragStyle: {
        color: '#000',
        opacity: 0.7,
        fontSize: '14px',
        fontWeight: '100'
    },
    addQuestion: {
        position: 'absolute',
        bottom: '24px',
        right: '24px'        
    }
})

const AccordionDetails = withStyles((theme) => ({
root: {
    padding: theme.spacing(2),
},
}))(MuiAccordionDetails);

const Faqs = () => {
    
    const {enqueueSnackbar} = useSnackbar();
    const profile = getProfile();
    const classes = useStyles();
    const [loading , setLoading] = React.useState(false);
    const [expanded, setExpanded] = React.useState('panel1');

    const [questions , setQuestions] = React.useState([])

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const getQuestions = () => {
        setLoading(true);        

        axios({
            method: 'get',				            				
            url: `${globalVars.API_URL}/faqs`,
            headers: {'Authorization' : `${getToken()}`}
        }).then( (res) => {    
            let { faqs = [] } = res.data; 
            faqs = faqs.sort((a , b) => {
                return (a.order > b.order) ? 1 : -1
            })
            setQuestions(faqs);
            setLoading(false);               
        });    
    }

    useEffect(() => {
        getQuestions();
    } , []);

    return (
        <Page pageName="profile" heading="سوالات متداول" auth_req={true}>
            {/* <SearchBox placeholder="سوال خود را جستجو کنید ..." /> */}
            {!loading ? <Box margin="24px">                
                {/* <Fab color="primary" aria-label="add" className={classes.addQuestion}>
                    <Plus />
                </Fab> */}
                {questions.length ? questions.map( (item , key) => {
                    return <Accordion key={key} square expanded={expanded === `panel${key}`} onChange={handleChange(`panel${key}`)}>
                        {item.question ? <AccordionSummary aria-controls={`panel${key}d-content`} id={`panel${key}d-header`}>
                            <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
                                <Typography>
                                {`${key + 1} - ${item.question}`}
                                </Typography>
                                <Box className="iconDropdown">
                                    <ChevronDown />
                                </Box>
                            </Box>
                        </AccordionSummary> : ''}
                        {item.answer ? <AccordionDetails>
                            <Typography className={classes.paragStyle}>
                                {item.answer}
                            </Typography>
                        </AccordionDetails> : ''}
                    </Accordion>;
                }) : <Box display="flex" justifyContent="center" alignItems="center" minHeight="80px">
                        <Typography component="p">
                            سوالی یافت نشد.
                        </Typography>
                    </Box>}             
            </Box> : <LoadingPart /> }
        </Page>
    )
}
export default Faqs;