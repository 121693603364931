import LogoIelts from "../global/logo"
import Box from '@material-ui/core/Box';
import {Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SplashScreenButton from "./buttons";

const useStyles = makeStyles((theme) => ({
    AppTitle: {
        color: '#000628',
        marginBottom: '24px'
    },
    DescTitle: {
        color: '#a4a8bf',
        fontSize: '18px',
        maxWidth: '60%',
        display: 'block',
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    Details: {
        textAlign: 'center'
    }
  }));
const SplashScreen = () => {
    const classes = useStyles();
    return (
        <div className="page splash-screen">
            <div className="logo-holder">
                <LogoIelts />     
            </div>
            <Box display="flex" justifyContent="center" flexDirection="column" className={classes.Details}>
                <Typography variant="h4" component="h1" className={classes.AppTitle}>
                    رایــــانیـــا
                </Typography>
                <Typography variant="body1" component="p" className={classes.DescTitle}>
                    اپلیکیشن آنلاین آموزش زبان سریع ساده، راحت
                </Typography>
            </Box>
            <SplashScreenButton />
        </div>
    )
}
export default SplashScreen;