import React, { useState, useEffect } from 'react';
import Page from '../../global/page';
import { Container, Box, makeStyles, Typography } from '@material-ui/core';
import globalVars from '../../../consts/vars';
import { getToken } from '../../../services/auth';
import LoadingPart from '../../global/loadingPart';
import axios from 'axios';
import { convertDate, convertFA } from '../../../services/utils';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    ticketRow: {
        position:'relative',
        padding: '16px',
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.12)',
        margin: '16px 0',
    },
    message: {
        marginBottom: '8px',
        fontSize: '14px',
        color: 'rgba(0,0,0,0.87)'
    },
    createdAt: {
        color: 'rgba(0,0,0,0.67)',
        fontSize: '12px'
    },
    ticketUser: {
        backgroundColor: 'rgba(0, 0, 255, 0.12)'
    },
    ticketAdmin: {
        backgroundColor: 'rgba(0, 255, 0, 0.12)'
    }
  }));

function TicketMessage(props) {

    const navigate = useHistory();
    const classes = useStyles();
    let {item = {}} = props;

    return (
        <Box className={`${classes.ticketRow} ${item.user == 'user' ? classes.ticketUser : classes.ticketAdmin}`}>
            <Box className={classes.message}>
                <Typography>{item.message}</Typography>
            </Box>
            <Box className={classes.createdAt}>
                {`ارسال شده در ${convertDate(item.created_at)} توسط ${item.user == 'user' ? 'من' : 'ادمین'}`}
            </Box>
        </Box>
    );
}

export default TicketMessage;