import React from 'react'

export default function ProgressLine(props) {
    let {color = '#61c984', item = {}} = props;
    return (
       <div className={`progress-line ${item.type}`}>
              <div className="progress-line-active" style={{width: `${props.progress}%` || 0 , backgroundColor: `${color}`}}></div>
        </div>
    )
}
