import {useState, useEffect} from 'react';
import {Slider, withStyles } from "@material-ui/core"
import VideoButton from "./button";
import { Percentage, millisecondsToHHMMSS } from '../../../services/utils';
import VideoButtonFullScreen from './button-fullscreen';
import { isIOS } from 'react-device-detect';

const ProgressSlider = withStyles({
    root: {
      color: '#6a46fe',
      height: '5px',
    },
    thumb: {
      height: 12,
      width: 12,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      marginTop: -4,
      marginLeft: -2,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
    },
    track: {
      height: 5,
      borderRadius: 4,
    },
    rail: {
      height: 5,
      borderRadius: 4,
    },
  })(Slider);

const VideoProgress = ({reactPlayer = {}, type, fullscreen = false, setFullscreen , isPlaying=false, setPlaying}) => {
    let currentTime = reactPlayer.current.getCurrentTime() || 0;
    let duration = reactPlayer.current.getDuration() || 0;
    const [progressVal , setProgress] = useState(0);
    const [progressPercentVal , setProgressPercent] = useState(0);

    const handleChangeVideo = (event, newValue) => {
        setProgressPercent(newValue);
        reactPlayer.current.seekTo( (newValue * duration) / 100 , 'seconds');
    }
    useEffect(() => {
        setProgressPercent(Percentage(currentTime , duration));
    } , [currentTime] )
    return <>
        <div className="video-controls-progress" dir="ltr">
            <VideoButton isPlaying={isPlaying} setPlaying={setPlaying} />
            <ProgressSlider value={Math.round(progressPercentVal > 100 ? 100 : progressPercentVal)} onChange={handleChangeVideo} aria-labelledby="disabled-slider"  />
            <span className="video-duration">-{millisecondsToHHMMSS(duration - currentTime)}</span>
            {type == 'video' && !isIOS ? <VideoButtonFullScreen fullscreen={fullscreen} setFullscreen={setFullscreen} /> : ''}
        </div>
    </>
}
export default VideoProgress;