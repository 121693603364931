import { Box, Button, Container, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Page from '../global/page'
import { Formik } from 'formik';
import * as Yup from 'yup';
import LoadingPart from '../global/loadingPart';
import axios from 'axios';
import globalVars from '../../consts/vars';
import { ResHandler, faNumberToEnNumber } from '../../services/utils';
import { useSnackbar } from 'notistack';
import { logIn } from '../../services/auth';
import {useHistory} from 'react-router-dom';

export default function Register() {	
	const { enqueueSnackbar } = useSnackbar();
	const [mobile , setMobile] = useState('');
	const [step, setStep] = useState(1);
	const navigate = useHistory(); 
	return (
		<Page className="register" heading="ثبت نام">
			<Container>
				<div className="register-holder">
				{step == 1 ? <Formik       
                        initialValues={{
							name: '',
                            mobile: ''
                        }}         
                        validationSchema={
                            Yup.object().shape({
								name: Yup.string().required('نام خانوادگی ضروری می باشد'),
                                mobile: Yup.string()
                                    .required('شماره موبایل ضروری می باشد')
                                    .matches(
                                    /^(\+98|0098|98|0)?9\d{9}$/,
                                    "لطفا موبایل معتبر انتخاب نمایید."
                                )
                            })
                        }
                        onSubmit={(form , actions) => {   

														
							let mobile = form.mobile;
							let name = form.name;
                                                
                            const formData = new FormData();                                
							formData.append('mobile', faNumberToEnNumber(mobile));                                                          
							formData.append('name', name);    

                            axios({
								method: 'post',								
                                url: `${globalVars.API_URL}/register`,
                                data: formData
                            }).then( (res) => {               
								ResHandler(res , (success) => {
									let {message = {}} = success;
									for (const [key, value] of Object.entries(message)) {
										enqueueSnackbar(value, {variant:'success'});
									}   
									setStep(2);
									actions.setSubmitting(false);    
								} , (error) => {
									let {errors = {}} = error;
									for (const [key, value] of Object.entries(errors)) {
										enqueueSnackbar(value, {variant:'error'});
									} 
									actions.setSubmitting(false);    
								});                                         								                                  
                            });

                            // setBodyLoading(true);                                             
                            
                        }}
                    >
                        {({
						errors,
						setFieldValue,
                        handleBlur,
                        handleChange,              
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values
                        }) => (
						<form onSubmit={handleSubmit} onChange={(e) => {
							if ( e.target.name == 'mobile' ) {
								let val = e.target.value.replace(/ /g,'');
								setFieldValue('mobile',faNumberToEnNumber(val));
								setMobile(faNumberToEnNumber(val));
							}
						}}>
							{!isSubmitting ? <>
								<TextField							
									label="نام و نام خانوادگی"
									variant="outlined"
									color="primary"
									name="name"
									value={values.name}
									error={Boolean(touched.name && errors.name)}
									helperText={touched.name && errors.name}
									onBlur={handleBlur} onChange={handleChange}
								/>
								<TextField
									label="شماره موبایل "
									variant="outlined"
									color="primary"
									name="mobile"
									value={values.mobile}
									error={Boolean(touched.mobile && errors.mobile)}
									helperText={touched.mobile && errors.mobile || values.mobile.length != 11 && 'کیبورد خود را در حالت انگلیسی قرار دهید'}
									onBlur={handleBlur} onChange={handleChange}
								/>
								<Button variant="contained" type="submit">
									ثبت نام
								</Button>								
							</> : <LoadingPart /> }
						</form>                        
                        )}
                    </Formik> : ''}
					{step == 2 ? <Formik       
                        initialValues={{							
                            code: ''
                        }}         
                        validationSchema={
                            Yup.object().shape({
								code: Yup.string().required('وارد کردن کد ورود الزامی می باشد.')
                            })
                        }
                        onSubmit={(form , actions) => {   

														
							let code = form.code;
							
                            const formData = new FormData();                                							
							formData.append('code', code);    
							formData.append('mobile', faNumberToEnNumber(mobile)); 

                            axios({
								method: 'post',								
                                url: `${globalVars.API_URL}/verifyCode`,
                                data: formData
                            }).then( (res) => {               
								ResHandler(res , (success) => {
									console.log(res);
									let {messages = {}} = success;
									let {token} = messages;
									setTimeout(() => {
										actions.setSubmitting(false);    
										logIn(token, navigate);	
									} , 1000);														
								} , (error) => {
									let {errors = {}} = error;
									for (const [key, value] of Object.entries(errors)) {
										enqueueSnackbar(value, {variant:'error'});
									} 
									actions.setSubmitting(false);    
								});                                         								                                  
                            });                                           
                            
                        }}
                    >
                        {({
						errors,
						setFieldValue,
                        handleBlur,
                        handleChange,              
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values
                        }) => (
						<form onSubmit={handleSubmit} onChange={(e) => {
							let val = e.target.value.replace(/ /g,'');
							setFieldValue('code',faNumberToEnNumber(val));
						}}>
							{!isSubmitting ? <>
								<TextField
									label="کد تایید"
									variant="outlined"
									color="primary"
									name="code"
									value={values.code}
									error={Boolean(touched.code && errors.code)}
									helperText={touched.code && errors.code}
									onBlur={handleBlur} onChange={handleChange}
								/>
								<Button variant="contained" type="submit">
									دریافت کد تایید
								</Button>								
							</> : <LoadingPart /> }
						</form>                        
                        )}
                    </Formik> : ''}
                    <Box display="flex" marginTop="24px">
                        <Link to="/login" ex>
                            <Typography style={{color: '#142f52'}}>
                            قبلا اکانت ساخته ام 
                            </Typography>
                        </Link>
                    </Box>    
				</div>
			</Container>
		</Page>
	)
}
