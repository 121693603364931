import AppHeader from "../global/header";
import Categories from "../categories/index";
import Courses from "../courses/index";
import Page from "../global/page";

const HomeComponent = () => {
    return   (
        <Page pageName="home" auth_req={true}>
            <Categories />            
            <Courses />
        </Page>
    );
}
export default HomeComponent;