import React, { useState, useEffect } from 'react';
import Page from '../../global/page';
import { Container, Box, makeStyles, Typography, TextField, Button } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import globalVars from '../../../consts/vars';
import { getToken } from '../../../services/auth';
import LoadingPart from '../../global/loadingPart';
import axios from 'axios';
import { convertFA, convertDate } from '../../../services/utils';
import TicketMessage from './ticket-message';
import { Formik } from "formik";
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    ticketRow: {
        cursor: 'pointer',
        position:'relative',
        padding: '16px',
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.12)',
        marginBottom: '16px',
        transition: '0.2s all',
        '&:hover': {
            boxShadow: '0 2px 16px rgba(0,0,0,0.12)'
        }
    },
    subject: {
        marginBottom: '16px'
    },
    status: {
        '& > strong': {
            fontWeight: 'bold'
        },
        '& > span': {
            marginLeft: '8px',
            color: 'rgba(0,0,0,0.43)'
        },
    },
    createdAt: {
        position: 'absolute',
        top: '16px',
        right: '16px'
    },
    ticketSingle: {
        position: 'relative'
    }
  }));

function TicketSingle(props) {

    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [ticket, setTicket] = useState({});
    const { ticket_id = null } = useParams();
    
    const navigate = useHistory();
    const {enqueueSnackbar} = useSnackbar();

    const getTicket = () => {
        
        setLoading(true);
        let url = `${globalVars.API_URL}/tickets/${ticket_id}`;
        
        axios({
            method: 'get',				            				
            url,
            headers: {'Authorization' : `${getToken()}`}
        }).then( (res) => {    
            let {ticket = []} = res.data;        
            setTicket(ticket);
            setLoading(false);
        });    
             
    }

    useEffect(() => {
        getTicket();
    } , [ticket_id]);

    return (
        <Page className="tickets" heading={ticket.subject}>
            <Container>
                {loading ? <LoadingPart /> : ticket && <Box className={classes.ticketSingle}>
                    {ticket.status ? <Box className={classes.status}>
                        <Typography component="strong">وضعیت تیکت:</Typography>
                        <Typography component="span">{convertFA(ticket.status)}</Typography>
                    </Box> : ''}
                    {ticket.priority ? <Box className={classes.status}>
                        <Typography component="strong">الویت:</Typography>
                        <Typography component="span">{convertFA(ticket.priority)}</Typography>
                    </Box> : ''}
                    <Formik       
                        initialValues={{
                            subject: '',
                            message: '',
                            priority: 'low',
                        }}         
                        validationSchema={
                            Yup.object().shape({
                                message: Yup.string().required('لطفا یک پیام وارد نمایید ...')                        
                            })
                        }
                        onSubmit={(form , actions) => {   
                            
                            let message = form.message;
                                                
                            const formData = new FormData();          
                            formData.append('message', message); 

                            setLoading(true);

                            axios({
                                method: 'post',								
                                url: `${globalVars.API_URL}/tickets/${ticket_id}`,
                                data: formData,
                                headers: {'Authorization' : `${getToken()}`}
                            }).then( (res) => {        
                                enqueueSnackbar(res.data.message, {variant:'success'});
                                getTicket();
                                setLoading(false);
                            }, (err) => {
                                let {message = ''} = err.response.data; 
                                enqueueSnackbar(message, {variant:'error'});
                                actions.setSubmitting(false); 
                                setLoading(false);
                            });
                                            
                            
                        }}
                    >
                        {({
                        errors,
                        handleBlur,
                        handleChange,              
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values
                        }) => (
                        <form onSubmit={handleSubmit} style={{marginTop:"24px"}}>
                            {!isSubmitting ? <>           
                                <TextField
                                    label="پیام"
                                    multiline={true}
                                    rows={6}
                                    variant="outlined"
                                    color="primary"
                                    name="message"
                                    defaultValue={values.message}
                                    error={Boolean(touched.message && errors.message)}
                                    helperText={touched.message && errors.message}
                                    onBlur={handleBlur} onChange={handleChange}
                                />                  
                                <Button variant="contained" color="primary" type="submit">
                                    افزودن پیغام جدید
                                </Button>								
                            </> : <LoadingPart /> }
                        </form>                        
                        )}
                    </Formik>
                    {ticket.messages && ticket.messages.length ? <Box marginTop="24px" className={classes.messages}>
                        {ticket.messages.map((item , key) => {
                          return <TicketMessage key={key} item={item} />  
                        })}
                    </Box> : ''}
                    <Box className={classes.createdAt}>
                        {convertDate(ticket.created_at)}
                    </Box>
                </Box>}
            </Container>
        </Page>
    );
}

export default TicketSingle;