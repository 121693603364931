import { Container, Typography , makeStyles, Grid, IconButton, Button, CircularProgress} from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { X, RotateCw } from 'react-feather'
import ProgressLine from '../../global/progress-line'
import { useParams, useHistory } from 'react-router-dom'
import dataCourse from '../../courses/data';
import dataLesson from '../../course/lessonData';
import { getCourseData, Percentage } from '../../../services/utils';
import FooterToast from '../../global/FooterToast'
import Question from './question'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import axios from 'axios';
import globalVars from '../../../consts/vars'
import { getToken } from '../../../services/auth'
import LoadingPart from '../../global/loadingPart'
import { useSnackbar } from 'notistack'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const useStyles  = makeStyles({
    CardTopHeading:{
        fontSize:'18px',
        fontWeight:'bold',
        color:'#092f52',

    },
    CourseName:{
       fontSize:'12px' ,
       fontWeight:'300',
       color:'#7f7f7f',
    }
})

export default function LessonExam() {

    const classes = useStyles();
    const {course_id, lesson_id} = useParams();
    const navigate = useHistory();
    const course = getCourseData(course_id, dataCourse);
    const lesson = getCourseData(lesson_id, dataLesson);
    const [questions, setLessonQuestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [examSubmitLoading, setExamSubmitLoading] = useState(false);
    const [answeredQuestion, setAnsweredQuestion] = useState([]);
    const [finalAnswered , setFinalAnswered] = useState([]);
    const [examResult, setExamResult] = useState('');
    const [rand, setRand] = useState(0);
    const [openCloseDialog, setOpenCloseDialog] = useState(false);
    const [openExamDialogFinal, setOpenExamDialogFinal] = useState(false);   
    const { enqueueSnackbar , closeSnackbar} = useSnackbar(); 
    const action = key => (
        <IconButton>
            <X color="#fff" size='16px' onClick={() => { closeSnackbar(key) }} />    
        </IconButton>  
    )
    const [correctAnswerdFinal , setCorrectAnswerdFinal] = useState(0);
    let exam = lesson && lesson.exam || {};
    let {title = 'خودآزمایی' , subTitle = 'معرفی دوره، نکات مهم ، کلیدی'} = exam;

    
    const handleAnswer = ( answer , index ) => {
        if ( answer )
            {
                let changedAnsweredQuestion = answeredQuestion;
                changedAnsweredQuestion[index] = answer;
                setAnsweredQuestion(changedAnsweredQuestion);
                setRand(Math.random(1000))
            }
    }

    const handleDialogOpen = () => {
        setOpenCloseDialog(true);
      };
    
    const handleDialogClose = () => {
        setOpenCloseDialog(false);
    };

    const mesureCorrectAnswered = async () => {       
        
        setExamSubmitLoading(true);
        let answers = [];

        let promise = new Promise((res, rej) => {
            setTimeout(() => {
                if ( questions.length )
                {
                    for ( let item of answeredQuestion ) {
                    let splitItem = item.split('-');
                    let answerItem = {
                        'q_id' : splitItem[0],
                        'a_id' : splitItem[1]
                    }
                    answers.push(answerItem);
                    }
                    setFinalAnswered(answers);  
                    res();     
                }
            }, 1000)
        });

        let result = await promise; 

        submitExam(answers);

    }


    const submitExam = (answers) => {   
        setExamSubmitLoading(true);     
        axios({
            method: 'post',				            				
            url: `${globalVars.API_URL}/courses/${course_id}/lessons/${lesson_id}/exam`,
            headers: {'Authorization' : `${getToken()}`},
            data: {
                answers
            }
        }).then( (res) => { 
            let { message = '' , correctAnswers = 0} = res.data; 
            setCorrectAnswerdFinal(correctAnswers);
            setExamSubmitLoading(false);
            setOpenExamDialogFinal(true);
            setExamResult(message);
            setRand(Math.random(10000))
        }, (error) => {
            setExamSubmitLoading(false);
        });  
    }

    const handleExamFinalDialogOpen = () => {
        mesureCorrectAnswered();
    };
    
    const handleExamFinalDialogClose = () => {
        setOpenExamDialogFinal(false);
        navigate.push(`/courses/${course_id}/lesson/${lesson_id}`);
    };   

    const mesaureValueOfCell = (answeredQuestion) => {
        let count = 0;
        for ( let item of answeredQuestion ) {
            if ( item != null ) {
                count++;
            }
        }
        return count;
    }

    const getExam = () => {
        setLoading(true);
        axios({
            method: 'get',				            				
            url: `${globalVars.API_URL}/courses/${course_id}/lessons/${lesson_id}/exam`,
            headers: {'Authorization' : `${getToken()}`}
        }).then( (res) => {    
            let {questions = []} = res.data;           
            setLoading(false);
            setLessonQuestions(questions);
        }, (error) => {
            let {data = {}} = error.response;
            for (const [key, value] of Object.entries(data.errors)) {
                if ( key === 'Didnt buy' ) {
                    navigate.push(`/courses/${course_id}?dialog=should-buy-course`);
                    return false;                    
                } {
                    
                    enqueueSnackbar(value, {variant:'error' , action});
                    navigate.goBack();
                    return false;
                }                
            }  
        });  
    }

    useEffect(() => {
        getExam();
    } , []);

    return (
        !loading ? <div className="card-tour">

            <Dialog
                    open={openCloseDialog}
                    keepMounted
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    className="dialogCloseExam"
                >
                <DialogTitle id="alert-dialog-slide-title">آیا می خواهید از آزمون خارج شوید؟</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <Typography component="span" style={{display: 'block', color: 'red'}}>توجه</Typography>
                    <Typography component="p">در صورت خروج از آزمون، در مراجعه بدی باید آزمون را از ابتدا شروع نمایید</Typography>
                </DialogContentText>
                </DialogContent>
                <DialogActions>                    
                <Button color="primary" onClick={() => navigate.push(`/courses/${course_id}/lesson/${lesson_id}`)}>
                    خارج می شوم
                </Button>
                <Button className="continue" onClick={handleDialogClose} color="primary">
                    باز می گردم
                </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                    open={openExamDialogFinal}
                    keepMounted
                    onClose={handleExamFinalDialogClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    className="dialogCloseExam dialogExamFinal fullBtn"
                >
                <DialogTitle id="alert-dialog-slide-title">{`شما به ${correctAnswerdFinal} سوال پاسخ درست دادید`}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <Typography component="span" style={{display: 'block', color: 'red'}}>توجه</Typography>
                    <Typography component="p">{examResult}</Typography>
                </DialogContentText>
                </DialogContent>
                <DialogActions>    
                <Button className="continue" onClick={() => navigate.push(`/courses/${course_id}/lesson/${lesson_id}`)} color="primary">
                    بازگشت به درس
                </Button>
                </DialogActions>
            </Dialog>

            <div className="card-header">
                <div className="right-card-head">
                    <div className="top-heading">
                        <Typography component="h2" className={classes.CardTopHeading}>
                            {title}
                        </Typography>
                    </div>
                    <div className="course-name" >
                        <Typography component="span" className={classes.CourseName} >
                             {subTitle}
                        </Typography>
                    </div>
                </div>
                <div className="left-card-head">
                    <button className="close" onClick={() => handleDialogOpen()}>
                        <X />
                    </button>
                </div>
            </div>
            {lesson && lesson.cards ? <ProgressLine color='#fd98aa' progress={Percentage(0 , lesson.cards && lesson.cards.length || 0)}/> : ''}
            <div className="exam-questions">
                {questions.length ? <>        
                    {questions.map( (item , key) => {
                        return <Question handleAnswer={handleAnswer} item={item} index={key} key={key} />
                    })}
                    <FooterToast buttonText={examSubmitLoading ? <CircularProgress size={24} style={{color: '#fff'}} /> : 'اتمام خودآزمایی'} action={handleExamFinalDialogOpen} isFinal={(mesaureValueOfCell(answeredQuestion) == questions.length) && !examSubmitLoading ? true : false} type='examFinal' title={`${questions.length} / ${mesaureValueOfCell(answeredQuestion)}`} />
                </> : <Typography component="p" style={{textAlign: 'center', marginTop: '24px'}}>هیچ سوالی در این خودآزمایی وجود ندارد.</Typography>}
            </div>
        </div> : <LoadingPart />
    )
}
